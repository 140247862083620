import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import ReactTooltip from 'react-tooltip'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import Pencil from "./pencil.svg";
import Delete from "./delete.svg";




class CompanyInfo extends Component {

    constructor(props){
        super(props);
        this.state={
            vyska: 5000,
            rozsah: 5000,
            vyska2: "",
            rozsah2: "",
            counter: 0,
            add: false,
            osoba: "fyzicka",
            titulPredMenom: "",
            titulZaMenom: "",
            meno: "",
            priezvisko: "",
            ulica: "",
            supCislo: "",
            addkonatel: "nic",
            obec: "",
            psc: "",
            datumNarodenie: "",
            rodneCislo: "",
            rodneCislo2: "",
            stat: "",
            totoznost: "",
            cisloTotoznosti: "",
            vyskaVkladu: 5000,
            podielSpolocnost: 100,
            rozsahSplatenia: 5000,
            meno2: "",
            priezvisko2: "",
            ulica2: "",
            statFirma: "",
            statFirma2: "",
            supCislo2: "",
            obec2: "",
            psc2: "",
            datumNarodenie2: "",
            stat2: "",
            totoznost2: "",
            cisloTotoznosti2: "",
            vyskaVkladu2: "",
            podielSpolocnost2: "",
            rozsahSplatenia2: "",
            spravcaVkladu: true,
            konatel: true,
            miestoNarodenia: "",
            miestoNarodenia2: "",
            statnaPrislusnost: "",
            statnaPrislusnost2: "",
            rodnePriezvisko: "",
            rodnePriezvisko2: "",
            editkonatel: false,
            predchadzajucePriezvisko: "",
            predchadzajucePriezvisko2: "",
            menoOtca: "",
            menoOtca2: "",
            priezviskoOtca: "",
            priezviskoOtca2: "",
            menoMatky: "",
            menoMatky2: "",
            rodnePriezviskoMatky: "",
            rodnePriezviskoMatky2: "",
            spolocnici: new Array(),
            pscFirma2: "",
            pscFirma: "",
            obecFirma: "",
            obecFirma2: "",
            supisneCisloFirma: "",
            supisneCisloFirma2: "",
            ulicaFirma: "",
            ulicaFirma2: "",
            ico: "",
            ico2: "",
            obchodneMeno: "",
            obchodneMeno2: "",
            menoKonatel: "",
            menoKonatel2: "",
            priezviskoKonatel: "",
            priezviskoKonatel2: "",
            datumNarodeniaFirma: "",
            datumNarodeniaFirma2: "",
            rodneCisloFirma: "",
            rodneCisloFirma2: "",
            vyskaVkladuFirma: 5000,
            vyskaVkladuFirma2: "",
            podielSpolocnostFirma: 100,
            podielSpolocnostFirma2: "",
            rozsahSplateniaFirma: 5000,
            rozsahSplateniaFirma2: "",
            vyskaVkladu2: "",
            edit: false,
            edittype: "",
            editid: "",
            notallowed: "",
            uzivatelVyhod: "zakladatelia",
            isSpravcaVkladu: false,
            uzivatelVyhodText: "",
            konanieKonatelovText: "",
            konanieKonatelov: "samostatne",
        }


        this.handleDelete = this.handleDelete.bind(this);

        this.handleKonanieKonatelovText = this.handleKonanieKonatelovText.bind(this);
        this.handleUzivatelVyhodText = this.handleUzivatelVyhodText.bind(this);

        this.handleEdit = this.handleEdit.bind(this);
        this.handleRozsahSplateniaFirma = this.handleRozsahSplateniaFirma.bind(this);
        this.handlePodielSpolocnostFirma = this.handlePodielSpolocnostFirma.bind(this);
        this.handleMenoKonatel = this.handleMenoKonatel.bind(this);
        this.handlePriezviskoKonatel = this.handlePriezviskoKonatel.bind(this);
        this.handleIco = this.handleIco.bind(this);
        this.handleObchodneMeno = this.handleObchodneMeno.bind(this);
        this.handleUlicaFirma = this.handleUlicaFirma.bind(this);
        this.handlePscFirma = this.handlePscFirma.bind(this);
        this.handleObecFirma = this.handleObecFirma.bind(this);
        this.handleFirmaSubmit = this.handleFirmaSubmit.bind(this);
        this.handleFirmaName = this.handleFirmaName.bind(this);
        this.handleFirmaForm = this.handleFirmaForm.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSidlo = this.handleSidlo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePriezvisko = this.handlePriezvisko.bind(this);
        this.handleMeno = this.handleMeno.bind(this);
        this.handleUlica = this.handleUlica.bind(this);
        this.handleMenoMatky = this.handleMenoMatky.bind(this);
        this.handleUlica = this.handleUlica.bind(this);
        this.handleSupisneCislo = this.handleSupisneCislo.bind(this);
        this.handleOrientacneCislo = this.handleOrientacneCislo.bind(this);
        this.handleObec = this.handleObec.bind(this);
        this.handlePsc = this.handlePsc.bind(this);
        this.addKonatel = this.addKonatel.bind(this);
        this.handleVlastnik = this.handleVlastnik.bind(this);
        this.handleDruh = this.handleDruh.bind(this);
        this.handleProgram = this.handleProgram.bind(this);
        this.handleVyska = this.handleVyska.bind(this);
        this.handleRozsah = this.handleRozsah.bind(this);
        this.addSpolocnik = this.addSpolocnik.bind(this);
        this.handleOsoba = this.handleOsoba.bind(this);
        this.handleTitulPredMenom = this.handleTitulPredMenom.bind(this);
        this.handleTitulZaMenom = this.handleTitulZaMenom.bind(this);
        this.handleStat = this.handleStat.bind(this);
        this.handleDatumNarodenia = this.handleDatumNarodenia.bind(this);
        this.handleSupCislo = this.handleSupCislo.bind(this);
        this.handleTotoznost = this.handleTotoznost.bind(this);
        this.handleCisloTotoznosti = this.handleCisloTotoznosti.bind(this);
        this.handleVyskaVladu = this.handleVyskaVladu.bind(this);
        this.handlePodielSpolocnost = this.handlePodielSpolocnost.bind(this);
        this.handleRozsahSplatenia = this.handleRozsahSplatenia.bind(this);
        this.handleRodneCislo = this.handleRodneCislo.bind(this);
        this.handleKonatel = this.handleKonatel.bind(this);
        this.handleMiestoNarodenia = this.handleMiestoNarodenia.bind(this);
        this.handleSpravcaVkladu = this.handleSpravcaVkladu.bind(this);
        this.handleStatnaPrislusnost = this.handleStatnaPrislusnost.bind(this);
        this.handleRodnePriezvisko = this.handleRodnePriezvisko.bind(this);
        this.handlePredchadzajucePriezvisko = this.handlePredchadzajucePriezvisko.bind(this);
        this.handleMenoOtca = this.handleMenoOtca.bind(this);
        this.handlePriezviskoOtca = this.handlePriezviskoOtca.bind(this);
        this.handlePriezviskoMatky = this.handlePriezviskoMatky.bind(this);
        this.handleRodnePriezviskoMatky = this.handleRodnePriezviskoMatky.bind(this);
        this.handleStatFirma = this.handleStatFirma.bind(this);
        this.handleVyskaVkladuFirma = this.handleVyskaVkladuFirma.bind(this);
        this.handleSupisneCisloFirma = this.handleSupisneCisloFirma.bind(this);
        this.handleRodneCisloFirma = this.handleRodneCisloFirma.bind(this);
        this.handleDatumNarodeniaFirma = this.handleDatumNarodeniaFirma.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.saveedit = this.saveedit.bind(this);
        this.handleUzivatelVyhod = this.handleUzivatelVyhod.bind(this);
        this.handleKonanieKonatelov = this.handleKonanieKonatelov.bind(this);


    }

    handleKonanieKonatelovText(event){
        this.setState({
            konanieKonatelovText: event.target.value,
        })
    }
    handleUzivatelVyhodText(event){
        this.setState({
            uzivatelVyhodText: event.target.value,
        })
    }
    handleUzivatelVyhod(event){

        this.setState({
            uzivatelVyhod: event.target.value,
        })
    }
    handleKonanieKonatelov(event){
        this.setState({
            konanieKonatelov: event.target.value,
        })
    }


    handleDelete(id){
        var spolocnici = this.state.spolocnici;

        spolocnici.splice(id, 1);

        this.setState({spolocnici: spolocnici});
    }

    saveedit(){

        var spolocnici = this.state.spolocnici;

        var id = 0;

        if(this.state.spravcaVkladu  == true){
            var spoloc = this.state.spolocnici;

            if(Array.isArray(spoloc) && spoloc.length > 0){
                spoloc.forEach(function (e) {
                    e.spravcaVkladu = false;
                });
            }
            this.setState({spolocnici: spoloc})
        }

        if(this.state.konatel == true){
            if (this.state.miestoNarodenia == "") {
                this.setState({
                    miestoNarodenia2: "Toto pole je povinné",
                })
                //event.preventDefault();
                return;
            }
            else {
                this.setState({
                    miestoNarodenia2: ""
                })
            }

            if (this.state.statnaPrislusnost == "") {
                this.setState({
                    statnaPrislusnost2: "Toto pole je povinné",
                })
               //event.preventDefault();
                return;
            }
            else {
                this.setState({
                    statnaPrislusnost2: ""
                })
            }

            if (this.state.rodnePriezvisko == "") {
                this.setState({
                    rodnePriezvisko2: "Toto pole je povinné",
                })
                //   event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodnePriezvisko2: ""
                })
            }


            if (this.state.menoOtca == "") {
                this.setState({
                    menoOtca2: "Toto pole je povinné",
                })
             //   event.preventDefault();
                return;
            }
            else {
                this.setState({
                    menoOtca2: ""
                })
            }
            if (this.state.priezviskoOtca == "Toto pole je povinné") {
                this.setState({
                    priezviskoOtca2: "",
                })
             //   event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezviskoOtca2: ""
                })
            }

            if (this.state.menoMatky == "") {
                this.setState({
                    menoMatky2: "Toto pole je povinné",
                })
             //   event.preventDefault();
                return;
            }
            else {
                this.setState({
                    menoMatky2: ""
                })
            }

            if (this.state.priezviskoMatky == "") {
                this.setState({
                    priezviskoMatky2: "Toto pole je povinné",
                })
             //   event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezviskoMatky2: ""
                })
            }
            if (this.state.rodnePriezviskoMatky == "") {
                this.setState({
                    rodnePriezviskoMatky2: "Toto pole je povinné",
                })
              //  event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodnePriezviskoMatky2: ""
                })
            }
        }

        spolocnici.forEach(function (e) {



            if(id == this.state.editid){



                if(e.osoba == "fyzicka"){

                    e.osoba = this.state.osoba;
                    e.titul = this.state.titulPredMenom;
                    e.meno = this.state.meno;
                    e.priezvisko = this.state.priezvisko;
                    e.titul2 = this.state.titulZaMenom;
                    e.ulica = this.state.ulica;
                    e.supCislo = this.state.supCislo;
                    e.obec = this.state.obec;
                    e.psc = this.state.psc;
                    e.stat = this.state.stat;
                    e.datumNarodenia = this.state.datumNarodenie;
                    e.rodneCislo = this.state.rodneCislo;
                    e.typDokladuTotoznosti = this.state.totoznost;
                    e.cisloDokladuTotoznosti = this.state.cisloTotoznosti;
                    e.vyskaVkladu = this.state.vyskaVkladu;
                    e.podielSpolocnost = this.state.podielSpolocnost;
                    e.rozsahSplatenia = this.state.rozsahSplatenia;
                    e.konatel = this.state.konatel;
                    e.spravcaVkladu = this.state.spravcaVkladu;
                    if(this.state.konatel == true && Array.isArray(e.registerTrestov)) {
                        e.registerTrestov.miestoNarodenia = this.state.miestoNarodenia;
                        e.registerTrestov.statnaPrislusnost = this.state.statnaPrislusnost;
                        e.registerTrestov.rodnePriezvisko = this.state.rodnePriezvisko;
                        e.registerTrestov.predchadzajucePriezvisko = this.state.predchadzajucePriezvisko;
                        e.registerTrestov.menoOtca = this.state.menoOtca;
                        e.registerTrestov.priezviskoOtca = this.state.priezviskoOtca;
                        e.registerTrestov.menoMatky = this.state.menoMatky;
                        e.registerTrestov.priezviskoMatky = this.state.priezviskoMatky;
                        e.registerTrestov.rodnePriezviskoMatky = this.state.rodnePriezviskoMatky;
                    }
                    else if(this.state.konatel==true){
                        var datas={
                            miestoNarodenia: this.state.miestoNarodenia,
                            statnaPrislusnost: this.state.statnaPrislusnost,
                            rodnePriezvisko: this.state.rodnePriezvisko,
                            predchadzajucePriezvisko : this.state.predchadzajucePriezvisko,
                            menoOtca : this.state.menoOtca,
                            priezviskoOtca : this.state.priezviskoOtca,
                            priezviskoMatky: this.state.priezviskoMatky,
                            menoMatky : this.state.menoMatky,
                            rodnePriezviskoMatky : this.state.rodnePriezviskoMatky,
                        }
                        e.registerTrestov = datas;
                    }

                    this.setState({spolocnici: spolocnici,
                    edit: false,
                    })





                }
                else if(e.osoba == "pravnicka"){



                    e.osoba = this.state.osoba;
                    e.ico = this.state.ico;
                    e.obchodneMeno = this.state.obchodneMeno;
                    e.ulica = this.state.ulicaFirma;
                    e.supisneCisloFirma = this.state.supisneCisloFirma;
                    e.obec = this.state.obecFirma;
                    e.psc = this.state.pscFirma;
                    e.stat = this.state.statFirma;
                    e.konatel.meno = this.state.menoKonatel;
                    e.konatel.priezvisko = this.state.priezviskoKonatel;
                    e.konatel.datumNarodenia = this.state.datumNarodeniaFirma;
                    e.konatel.rodneCislo = this.state.rodneCisloFirma;
                    e.vyskaVkladuFirma = this.state.vyskaVkladuFirma;
                    e.podielSpolocnostFirma = this.state.podielSpolocnostFirma;
                    e.rozsahSplateniaFirma = this.state.rozsahSplateniaFirma

                    this.setState({spolocnici: spolocnici,
                        edit: false,
                    })



                }

            }
           id++;
        }.bind(this));

    }
    handleEdit(id){
        this.setState({edit: true,
            editid: id
        });

        var spolocnici = this.state.spolocnici;
        var ide=0;
        spolocnici.forEach(function (e) {
            if(ide==id){
                console.log(e);

                if(e.justkonatel == true){
                    this.setState({
                        editkonatel: true,
                    });
                    console.log(e)
                }else{
                    this.setState({
                        editkonatel: false,
                    });
                }

                this.setState({edittype: e.osoba});


                if(e.osoba == "fyzicka") {

                    if(e.konatel == true) {

                        this.setState({
                            osoba: e.osoba,
                            titulPredMenom: e.titul,
                            meno: e.meno,
                            priezvisko: e.priezvisko,
                            titulZaMenom: e.titul2,
                            ulica: e.ulica,
                            supCislo: e.supCislo,
                            obec: e.obec,
                            psc: e.psc,
                            stat: e.stat,
                            datumNarodenie: e.datumNarodenia,
                            rodneCislo: e.rodneCislo,
                            totoznost: e.typDokladuTotoznosti,
                            cisloTotoznosti: e.cisloDokladuTotoznosti,
                            vyskaVkladu: e.vyskaVkladu,
                            podielSpolocnost: e.podielSpolocnost,
                            rozsahSplatenia: e.rozsahSplatenia,
                            konatel: e.konatel,
                            spravcaVkladu: e.spravcaVkladu,
                            miestoNarodenia: e.registerTrestov.miestoNarodenia,
                            statnaPrislusnost: e.registerTrestov.statnaPrislusnost,
                            rodnePriezvisko: e.registerTrestov.rodnePriezvisko,
                            predchadzajucePriezvisko: e.registerTrestov.predchadzajucePriezvisko,
                            menoOtca: e.registerTrestov.menoOtca,
                            priezviskoOtca: e.registerTrestov.priezviskoOtca,
                            menoMatky: e.registerTrestov.menoMatky,
                            priezviskoMatky: e.registerTrestov.priezviskoMatky,
                            rodnePriezviskoMatky: e.registerTrestov.rodnePriezviskoMatky,


                        });
                    }
                    else{
                        this.setState({
                            osoba: e.osoba,
                            titulPredMenom: e.titul,
                            meno: e.meno,
                            priezvisko: e.priezvisko,
                            titulZaMenom: e.titul2,
                            ulica: e.ulica,
                            supCislo: e.supCislo,
                            obec: e.obec,
                            psc: e.psc,
                            stat: e.stat,
                            datumNarodenie: e.datumNarodenia,
                            rodneCislo: e.rodneCislo,
                            totoznost: e.typDokladuTotoznosti,
                            cisloTotoznosti: e.cisloDokladuTotoznosti,
                            vyskaVkladu: e.vyskaVkladu,
                            podielSpolocnost: e.podielSpolocnost,
                            rozsahSplatenia: e.rozsahSplatenia,
                            konatel: e.konatel,
                            spravcaVkladu: e.spravcaVkladu,


                            miestoNarodenia: "",
                            statnaPrislusnost: "",
                            rodnePriezvisko: "",
                            predchadzajucePriezvisko: "",
                            menoOtca: "",
                            priezviskoOtca: "",
                            menoMatky: "",
                            priezviskoMatky: "",
                            rodnePriezviskoMatky: "",



                        });
                    }
                }

                else if(e.osoba == "pravnicka"){

                    this.setState({

                        osoba: e.osoba,
                        ico: e.ico,
                        obchodneMeno: e.obchodneMeno,
                        ulicaFirma: e.ulica,
                        supisneCisloFirma: e.supisneCisloFirma,
                        obecFirma: e.obec,
                        pscFirma: e.psc,
                        statFirma: e.stat,
                        menoKonatel: e.konatel.meno,
                        priezviskoKonatel: e.konatel.priezvisko,
                        datumNarodeniaFirma: e.konatel.datumNarodenia,
                        rodneCisloFirma: e.konatel.rodneCislo,
                        vyskaVkladuFirma: e.vyskaVkladuFirma,
                        podielSpolocnostFirma: e.podielSpolocnostFirma,
                        rozsahSplateniaFirma: e.rozsahSplateniaFirma,

                    });
                }




            }

            ide++;
        }.bind(this));


    }

    handleSubmitForm(event) {

        var konatelis = false;



        if(this.state.spolocnici.length == 0){
            this.setState({
                notallowed: "Vložte minimálne jedného zakladateľa (spoločníka).",
            });
            event.preventDefault();
            return;
        }
        else{
            this.state.spolocnici.forEach(function (e) {
               if(e.konatel == true){
                   konatelis = true;
               }
            }.bind(this));
        }

        if(konatelis == false){
            this.setState({
                notallowed: "Vložte minimálne jedného konateľa.",
            });
            event.preventDefault();
            return;
        }

        console.log(JSON.stringify(this.state.spolocnici));




        var datas = {
            imanie: this.state.vyska,
            splatene: this.state.rozsah,
            spolocnici: this.state.spolocnici,
            uzivatelVyhod: this.state.uzivatelVyhod,
            konanieKonatelov: this.state.konanieKonatelov,
            uzivatelVyhodText: this.state.uzivatelVyhodText,
            konanieKonatelovText: this.state.konanieKonatelovText,
        }

        localStorage.setItem("secondSubmit", JSON.stringify(datas));

        this.props.makeThird();

        this.props.secondSubmit(datas);
        event.preventDefault();
    }


    handleDatumNarodeniaFirma(event){
        this.setState({datumNarodeniaFirma: event.target.value})
    }

    handleRodneCisloFirma(event){
        this.setState({rodneCisloFirma: event.target.value})
    }
    handleVyskaVkladuFirma(event){
        this.setState({vyskaVkladuFirma: event.target.value})
    }
    handlePodielSpolocnostFirma(event){
        this.setState({podielSpolocnostFirma: event.target.value})
    }
    handleRozsahSplateniaFirma(event){
        this.setState({rozsahSplateniaFirma: event.target.value})
    }
    handleMenoKonatel(event){
        this.setState({menoKonatel: event.target.value})
    }
    handlePriezviskoKonatel(event){
        this.setState({priezviskoKonatel: event.target.value})
    }
    handleObchodneMeno(event){
        this.setState({obchodneMeno: event.target.value})
    }
    handleIco(event){
        this.setState({ico: event.target.value})
    }
    handleUlicaFirma(event){
        this.setState({ulicaFirma: event.target.value})
    }
    handleSupisneCisloFirma(event){
        this.setState({supisneCisloFirma: event.target.value})
    }
    handleObecFirma(event){
        this.setState({obecFirma: event.target.value})
    }
    handleStatFirma(event){
        this.setState({statFirma: event.target.value})
    }
    handleRodnePriezviskoMatky(event){
        this.setState({rodnePriezviskoMatky: event.target.value})
    }
    handleMenoMatky(event){
        this.setState({
            menoMatky: event.target.value,
        })
    }

    handlePscFirma(event){
        this.setState({pscFirma: event.target.value})
    }
    handlePriezviskoMatky(event){
        this.setState({
            priezviskoMatky: event.target.value,
        })
    }
    handlePriezviskoOtca(event){
        this.setState({
            priezviskoOtca: event.target.value,
        })
    }
    handlePredchadzajucePriezvisko(event){
        this.setState({
            predchadzajucePriezvisko: event.target.value,
        })
    }

    handleMenoOtca(event){
        this.setState({
            menoOtca: event.target.value,
        })
    }

    handleStatnaPrislusnost(event){
        this.setState({statnaPrislusnost: event.target.value})
    }

    handleRodnePriezvisko(event){
        this.setState({rodnePriezvisko: event.target.value})
    }

    handleMiestoNarodenia(event){
        this.setState({miestoNarodenia: event.target.value})
    }
    handleKonatel(event){
        this.setState({konatel: !this.state.konatel})
    }

    handleSpravcaVkladu(event){
        if(!this.state.spravcaVkladu == false && this.state.counter == 0){
            this.setState({spravcaVkladu2: "Správca vkladu musí byť ustanovený ak je zakladateľ len jeden!"})
        }
        else{
            this.setState({spravcaVkladu2: ""})
        }
        this.setState({spravcaVkladu: !this.state.spravcaVkladu})
    }

    handleRodneCislo(event){
        this.setState({rodneCislo: event.target.value})
    }
    handleVyskaVladu(event){
        if(this.state.counter == 0 && event.target.value < this.state.vyska){
            var str = "Minimálna hodnota: " + this.state.vyska;
            this.setState({vyskaVkladu2: str});
        }
        else if(this.state.counter > 0 && event.target.value < 750 ){
            var str = "Minimálna hodnota: 750";
            this.setState({vyskaVkladu2: str});
        }
        else{
            this.setState({vyskaVkladu2: ""});
        }
        this.setState({vyskaVkladu: event.target.value})
    }
    handlePodielSpolocnost(event){
        this.setState({podielSpolocnost: event.target.value})
    }
    handleRozsahSplatenia(event){
        this.setState({rozsahSplatenia: event.target.value})
    }


    handleCisloTotoznosti(event){
        this.setState({
            cisloTotoznosti: event.target.value,
        })
    }
    handleTotoznost(event){
        this.setState({
            totoznost:event.target.value,
        })
    }
    addSpolocnik(){


        this.setState({
            counter: this.state.counter++,
            add: true,

        });



        var spoloc = this.state.spolocnici;

        var x=1;
            if(Array.isArray(spoloc) && spoloc.length > 0){

                this.setState({
                    addkonatel: false,
                })

                spoloc.forEach(function (e) {
                    if(!(e.justkonatel == true)){
                        x++;
                    }
                }.bind(this));





               var vyska = this.state.vyska;

               var v = vyska / x;

               this.setState({
                   vyskaVkladu: this.state.vyska - (Math.floor(v)*(x-1)),
                   vyskaVkladuFirma: this.state.vyska - (Math.floor(v)*(x-1)),
                   rozsahSplatenia: this.state.vyska - (Math.floor(v)*(x-1)),
                   rozsahSplateniaFirma: this.state.vyska - (Math.floor(v)*(x-1)),
                   podielSpolocnost: 100 / (this.state.vyska/(this.state.vyska - (Math.floor(v)*(x-1)))),
                   podielSpolocnostFirma: 100 / (this.state.vyska/(this.state.vyska - (Math.floor(v)*(x-1)))),
               })
            }else{
                x=1;
            }


            this.setState({spolocnici: spoloc});

        this.setState({
            titulPredMenom: "",
            meno: "",
            priezvisko: "",
            titulZaMenom: "",
            ulica: "",
            supCislo: "",
            obec: "",
            psc: "",
            stat: "",
            datumNarodenie: "",
            rodneCislo: "",
            totoznost: "",
            cisloTotoznosti: "",


            miestoNarodenia: "",
            statnaPrislusnost: "",
            rodnePriezvisko: "",
            predchadzajucePriezvisko: "",
            menoOtca: "",
            priezviskoOtca: "",
            menoMatky: "",
            priezviskoMatky: "",
            rodnePriezviskoMatky: "",
        });

        this.setState({
            ico: "",
            obchodneMeno: "",
            ulicaFirma: "",
            supisneCisloFirma: "",
            obecFirma: "",
            pscFirma: "",
            statFirma: "",
            menoKonatel: "",
            priezviskoKonatel: "",
            datumNarodeniaFirma: "",
            rodneCisloFirma: "",

        });


    }

    addKonatel(){


        this.setState({
            counter: this.state.counter++,
            addkonatel: true,
            add: false,
        })

        var spoloc = this.state.spolocnici;

        var x=0;
        if(Array.isArray(spoloc) && spoloc.length > 0){



            spoloc.forEach(function (e) {
                if(!(e.justkonatel == true)){
                    x++;
                }
            }.bind(this));

            if(x==0){
                x=1;
            }



            var vyska = this.state.vyska;

            var v = vyska / x;

            this.setState({
                vyskaVkladu: this.state.vyska - (Math.floor(v)*(x-1)),
                vyskaVkladuFirma: this.state.vyska - (Math.floor(v)*(x-1)),
                rozsahSplatenia: this.state.vyska - (Math.floor(v)*(x-1)),
                rozsahSplateniaFirma: this.state.vyska - (Math.floor(v)*(x-1)),
                podielSpolocnost: 100 / (this.state.vyska/(this.state.vyska - (Math.floor(v)*(x-1)))),
                podielSpolocnostFirma: 100 / (this.state.vyska/(this.state.vyska - (Math.floor(v)*(x-1)))),
            })
        }


        this.setState({spolocnici: spoloc});

        this.setState({
            titulPredMenom: "",
            meno: "",
            priezvisko: "",
            titulZaMenom: "",
            ulica: "",
            supCislo: "",
            obec: "",
            psc: "",
            stat: "",
            datumNarodenie: "",
            rodneCislo: "",
            totoznost: "",
            cisloTotoznosti: "",


            miestoNarodenia: "",
            statnaPrislusnost: "",
            rodnePriezvisko: "",
            predchadzajucePriezvisko: "",
            menoOtca: "",
            priezviskoOtca: "",
            menoMatky: "",
            priezviskoMatky: "",
            rodnePriezviskoMatky: "",
        });

        this.setState({
            ico: "",
            obchodneMeno: "",
            ulicaFirma: "",
            supisneCisloFirma: "",
            obecFirma: "",
            pscFirma: "",
            statFirma: "",
            menoKonatel: "",
            priezviskoKonatel: "",
            datumNarodeniaFirma: "",
            rodneCisloFirma: "",

        });


    }

    handleStat(event){
        this.setState({
          stat: event.target.value,
        })
    }
    handleDatumNarodenia(event){
        this.setState({
            datumNarodenie: event.target.value,
        })
    }

    handleUlica(event){
        this.setState({
            ulica: event.target.value,
        })
    }
    handlePriezvisko(event){
        this.setState({
            priezvisko: event.target.value,
        })
    }
    handleMeno(event){
        this.setState({
            meno: event.target.value,
        })
    }

    handleTitulZaMenom(event){
        this.setState({
            titulZaMenom:event.target.value,
        })
    }
    handleTitulPredMenom(event){
        this.setState({
            titulPredMenom: event.target.value,
        })
    }
    handleVlastnik(event){
        this.setState({vlastnik: event.target.value})
    }
    handleRozsah(event){
        if(this.state.vyska < event.target.value){
            var str = "Maximálna hodnota: " + this.state.vyska;
            this.setState({rozsah2: str});
        }
        else{
            this.setState({rozsah2: ""});
        }
        this.setState({rozsah: event.target.value});
        this.setState({rozsahSplatenia: event.target.value});
        this.setState({rozsahSplateniaFirma: event.target.value});

    }
    handleVyska(event){


        if(event.target.value < this.state.rozsah){
            var str = "Maximálna hodnota: " + event.target.value;
            this.setState({rozsah2: str});
        }
        else{
            this.setState({rozsah2: ""});
        }




        if(event.target.value < 5000){
            NotificationManager.warning(null, "Minimálna hodnota je 5000");
            this.setState({vyska2: "Minimálna hodnota je 5000€"});
        }
        else{
            this.setState({vyska2: ""});
        }
        this.setState({
            vyska: event.target.value,vyskaVkladu: event.target.value,
            vyskaVkladuFirma: event.target.value,
        });
    }

    handleDruh(event){
        this.setState({druh: event.target.value})
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.addlinka("two");
        var submit = localStorage.getItem("secondSubmit");
        if(submit != null && submit != "proccess")
        submit = JSON.parse(submit);

        if(submit != null && submit != "proccess"){
            this.setState({
                vyska: submit.imanie,
                rozsah: submit.splatene,
                spolocnici: submit.spolocnici,
                uzivatelVyhol: submit.uzivatelVyhod,
                konanieKonatelov: submit.konanieKonatelov,
                uzivatelVyhodText: submit.uzivatelVyhodText,
                konanieKonatelovText: submit.konanieKonatelovText
            })

            submit.spolocnici.forEach(function (e) {
               if(e.justkonatel == false || e.justkonatel == null){
                   this.setState({
                       addkonatel: false,
                   })
               }
            }.bind(this));

        }
        else{
            localStorage.setItem("secondSubmit", "proccess");
        }






    }
    handleUlica(event){
        this.setState({
            ulica: event.target.value,
        })
    }
    handleSupisneCislo(event){
        this.setState({
            supisneCislo: event.target.value,
        })
    }
    handleOrientacneCislo(event){
        this.setState({
            orientacneCislo: event.target.value,
        })
    }
    handleObec(event){
        this.setState({
            obec: event.target.value,
        })
    }
    handlePsc(event){
        this.setState({
            psc: event.target.value,
        })
    }
    handleSidlo(event){
        this.setState({
            sidlo: event.target.value,
        })
    }
    handleProgram(event){
        this.setState({program: event.target.value})
    }
    handleSupCislo(event){
        this.setState({
            supCislo: event.target.value,
        })
    }

    handleSubmit(event){


        var spol = this.state.spolocnici;

        if(Array.isArray(spol) && spol.length > 0) {

            var x = 1;



                spol.forEach(function (e) {
                    if ((e.justkonatel == false || e.justkonatel == null)) {

                        x++;
                        console.log(x);
                    }
                }.bind(this));





            var vyska = this.state.vyska;

            var v = vyska / x;


            var inc = 1;

          //  console.log(x);


            event.preventDefault();


            if (this.state.addkonatel == false) {

                spol.forEach(function (e) {
                    if (e.justkonatel == false || e.justkonatel == null) {


                        if (inc == x) {

                            console.log(e)
                            console.log("toto");
                            if (e.osoba == 'fyzicka') {

                                e.vyskaVkladu = this.state.vyska - (Math.floor(v) * (x - 1));
                                e.podielSpolocnost = 100 / (this.state.vyska / (Math.floor(v) * (x - 1)));
                                e.rozsahSplatenia = this.state.vyska - (Math.floor(v) * (x - 1));
                            }
                            else {
                                e.vyskaVkladuFirma = this.state.vyska - (Math.floor(v) * (x - 1));
                                e.podielSpolocnostFirma = 100 / (this.state.vyska / (Math.floor(v) * (x - 1)));
                                e.rozsahSplateniaFirma = this.state.vyska - (Math.floor(v) * (x - 1));
                            }

                        }
                        else {
                            console.log("toto2");
                            if (e.osoba == 'fyzicka') {
                                e.vyskaVkladu = Math.round(v);
                                e.podielSpolocnost = 100 / (this.state.vyska / (Math.round(v)));
                                e.rozsahSplatenia = Math.round(v);
                            }
                            else {
                                e.vyskaVkladuFirma = Math.round(v);
                                e.podielSpolocnostFirma = 100 / (this.state.vyska / (Math.round(v)));
                                e.rozsahSplateniaFirma = Math.round(v);
                            }
                        }

                        inc++;
                    }

                }.bind(this));


            }
        }
        event.preventDefault();
        if(this.state.osoba == 'fyzicka') {



            if(this.state.spravcaVkladu  == true){
                var spoloc = this.state.spolocnici;

                if(Array.isArray(spoloc) && spoloc.length > 0){
                    spoloc.forEach(function (e) {
                       e.spravcaVkladu = false;
                    });
                }
                this.setState({spolocnici: spoloc})
            }

            if (this.state.meno == "") {
                this.setState({
                    meno2: "Toto pole je povinné"
                });
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    meno2: ""
                })
            }
            if (this.state.priezvisko == "") {
                this.setState({
                    priezvisko2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    priezvisko2: ""
                })
            }
            if (this.state.ulica == "") {
                this.setState({
                    ulica2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    ulica2: ""
                })
            }
            if (this.state.supCislo == "") {
                this.setState({
                    supCislo2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    supCislo2: ""
                })
            }
            if (this.state.obec == "") {
                this.setState({
                    obec2: "Toto pole je povinné"
                })
                event.preventDefault();
            }
            else {
                this.setState({
                    obec2: ""
                })
            }
            if (this.state.psc == "") {
                this.setState({
                    psc2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    psc2: ""
                })
            }
            if (this.state.datumNarodenie == "") {
                this.setState({
                    datumNarodenie2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;

            }
            else {
                this.setState({
                    datumNarodeni2: ""
                })
            }
            if (this.state.stat == "") {
                this.setState({
                    stat2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    stat2: ""
                })
            }
            if (this.state.totoznost == "") {
                this.setState({
                    totoznost2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    totoznost2: ""
                })
            }
            if (this.state.cisloTotoznosti == "") {
                this.setState({
                    cisloTotoznosti2: "Toto pole je povinné"
                })
                event.preventDefault();
            }
            else {
                this.setState({
                    cisloTotoznosti2: ""
                })
            }
            if (this.state.vyskaVkladu == "") {
                this.setState({
                    vyskaVkladu2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    vyskaVkladu2: ""
                })
            }
            if (this.state.podielSpolocnost == "") {
                this.setState({
                    podielSpolocnost2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    podielSpolocnost2: ""
                })
            }
            if (this.state.rozsahSplatenia == "") {
                this.setState({
                    rozsahSplatenia2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rozsahSplatenia2: ""
                })
            }
            if (this.state.rodneCislo == "") {
                this.setState({
                    rodneCislo2: "Toto pole je povinné"
                })
                event.preventDefault();
                return;
            }
            else {
                this.setState({
                    rodneCislo2: ""
                })
            }



            if(this.state.konatel == true) {

                if (this.state.miestoNarodenia == "") {
                    this.setState({
                        miestoNarodenia2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        miestoNarodenia2: ""
                    })
                }

                if (this.state.statnaPrislusnost == "") {
                    this.setState({
                        statnaPrislusnost2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        statnaPrislusnost2: ""
                    })
                }


                if (this.state.menoOtca == "") {
                    this.setState({
                        menoOtca2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        menoOtca2: ""
                    })
                }
                if (this.state.priezviskoOtca == "Toto pole je povinné") {
                    this.setState({
                        priezviskoOtca2: "",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        priezviskoOtca2: ""
                    })
                }

                if (this.state.menoMatky == "") {
                    this.setState({
                        menoMatky2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        menoMatky2: ""
                    })
                }

                if (this.state.priezviskoMatky == "") {
                    this.setState({
                        priezviskoMatky2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        priezviskoMatky2: ""
                    })
                }
                if (this.state.rodnePriezviskoMatky == "") {
                    this.setState({
                        rodnePriezviskoMatky2: "Toto pole je povinné",
                    })
                    event.preventDefault();
                    return;
                }
                else {
                    this.setState({
                        rodnePriezviskoMatky2: ""
                    })
                }
            }

            if(this.state.konatel == true && this.state.addkonatel == true) {

                var datas = {
                    osoba: this.state.osoba,
                    titul: this.state.titulPredMenom,
                    meno: this.state.meno,
                    priezvisko: this.state.priezvisko,
                    titul2: this.state.titulZaMenom,
                    ulica: this.state.ulica,
                    supCislo: this.state.supCislo,
                    obec: this.state.obec,
                    psc: this.state.psc,
                    stat: this.state.stat,
                    datumNarodenia: this.state.datumNarodenie,
                    rodneCislo: this.state.rodneCislo,
                    typDokladuTotoznosti: this.state.totoznost,
                    cisloDokladuTotoznosti: this.state.cisloTotoznosti,
                    konatel: this.state.konatel,
                    spravcaVkladu: false,
                    justkonatel: true,
                    spravcaVkladu: this.state.spravcaVkladu,
                    registerTrestov: {
                        miestoNarodenia: this.state.miestoNarodenia,
                        statnaPrislusnost: this.state.statnaPrislusnost,
                        rodnePriezvisko: this.state.rodnePriezvisko,
                        predchadzajucePriezvisko: this.state.predchadzajucePriezvisko,
                        menoOtca: this.state.menoOtca,
                        priezviskoOtca: this.state.priezviskoOtca,
                        menoMatky: this.state.menoMatky,
                        priezviskoMatky: this.state.priezviskoMatky,
                        rodnePriezviskoMatky: this.state.rodnePriezviskoMatky
                    }

                }

                console.log(datas);
            }


            else if(this.state.konatel == true) {

                var datas = {
                    osoba: this.state.osoba,
                    titul: this.state.titulPredMenom,
                    meno: this.state.meno,
                    priezvisko: this.state.priezvisko,
                    titul2: this.state.titulZaMenom,
                    ulica: this.state.ulica,
                    supCislo: this.state.supCislo,
                    obec: this.state.obec,
                    psc: this.state.psc,
                    stat: this.state.stat,
                    datumNarodenia: this.state.datumNarodenie,
                    rodneCislo: this.state.rodneCislo,
                    typDokladuTotoznosti: this.state.totoznost,
                    cisloDokladuTotoznosti: this.state.cisloTotoznosti,
                    vyskaVkladu: this.state.vyskaVkladu,
                    podielSpolocnost: this.state.podielSpolocnost,
                    rozsahSplatenia: this.state.rozsahSplatenia,
                    konatel: this.state.konatel,
                    spravcaVkladu: this.state.spravcaVkladu,
                    registerTrestov: {
                        miestoNarodenia: this.state.miestoNarodenia,
                        statnaPrislusnost: this.state.statnaPrislusnost,
                        rodnePriezvisko: this.state.rodnePriezvisko,
                        predchadzajucePriezvisko: this.state.predchadzajucePriezvisko,
                        menoOtca: this.state.menoOtca,
                        priezviskoOtca: this.state.priezviskoOtca,
                        menoMatky: this.state.menoMatky,
                        priezviskoMatky: this.state.priezviskoMatky,
                        rodnePriezviskoMatky: this.state.rodnePriezviskoMatky
                    }

                }
            }
            else{
                var datas = {
                    osoba: this.state.osoba,
                    titul: this.state.titulPredMenom,
                    meno: this.state.meno,
                    priezvisko: this.state.priezvisko,
                    titul2: this.state.titulZaMenom,
                    ulica: this.state.ulica,
                    supCislo: this.state.supCislo,
                    obec: this.state.obec,
                    psc: this.state.psc,
                    stat: this.state.stat,
                    datumNarodenia: this.state.datumNarodenie,
                    rodneCislo: this.state.rodneCislo,
                    typDokladuTotoznosti: this.state.totoznost,
                    cisloDokladuTotoznosti: this.state.cisloTotoznosti,
                    vyskaVkladu: this.state.vyskaVkladu,
                    podielSpolocnost: this.state.podielSpolocnost,
                    rozsahSplatenia: this.state.rozsahSplatenia,
                    konatel: this.state.konatel,
                    spravcaVkladu: this.state.spravcaVkladu,
                }
            }

            if(this.state.spravcaVkladu == true){
                this.setState({isSpravcaVkladu: true, spravcaVkladu: false});
            }



            var spolocnici = this.state.spolocnici;
            spolocnici.push(datas);

            this.setState({
                add: false,
                addkonatel:false,
                spolocnici: spolocnici,
            })


            event.preventDefault();
        }
        else{
            if(this.state.ico == ""){
                this.setState({
                    ico2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    ico2: "",
                })
            }

            if(this.state.obchodneMeno == ""){
                this.setState({
                    obchodneMeno2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    obchodneMeno2: "",
                })
            }
            if(this.state.ulicaFirma == ""){
                this.setState({
                    ulicaFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    ulicaFirma2: "",
                })
            }
            if(this.state.supisneCisloFirma == ""){
                this.setState({
                    supisneCisloFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    supisneCisloFirma2: "",
                })
            }
            if(this.state.obecFirma == ""){
                this.setState({
                    obecFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    obecFirma2: "",
                })
            }
            if(this.state.pscFirma == ""){
                this.setState({
                    pscFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    pscFirma2: "",
                })
            }
            if(this.state.statFirma == ""){
                this.setState({
                    statFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    statFirma2: "",
                })
            }

            if(this.state.menoKonatel == ""){
                this.setState({
                    menoKonatel2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    menoKonatel2: ""
                })
            }

            if(this.state.priezviskoKonatel == ""){
                this.setState({
                    priezviskoKonatel2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    priezviskoKonatel2: "",
                })
            }

            if(this.state.datumNarodeniaFirma == ""){
                this.setState({
                    datumNarodeniaFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    datumNarodeniaFirma2: "",
                })
            }

            if(this.state.rodneCisloFirma == ""){
                this.setState({
                    rodneCisloFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    rodneCisloFirma2: "",
                })
            }
            if(this.state.vyskaVkladuFirma == ""){
                this.setState({
                    vyskaVkladu2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    vyskaVkladu2: "",
                })
            }

            if(this.state.podielSpolocnostFirma == ""){
                this.setState({
                    podielSpolocnostFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    podielSpolocnostFirma2: "",
                })
            }

            if(this.state.rozsahSplateniaFirma == ""){
                this.setState({
                    rozsahSplatenieFirma2: "Toto pole je povinné",
                })
                event.preventDefault();
                return;
            }
            else{
                this.setState({
                    rozsahSplatenieFirma2: "",
                })
            }

            var datas = {
                osoba: this.state.osoba,
                ico: this.state.ico,
                obchodneMeno: this.state.obchodneMeno,
                ulica: this.state.ulicaFirma,
                supisneCisloFirma: this.state.supisneCisloFirma,
                obec: this.state.obecFirma,
                psc: this.state.pscFirma,
                stat: this.state.statFirma,
                vyskaVkladuFirma: this.state.vyskaVkladuFirma,
                podielSpolocnostFirma: this.state.podielSpolocnostFirma,
                rozsahSplateniaFirma: this.state.rozsahSplateniaFirma,
                konatel: {
                    meno: this.state.menoKonatel,
                    priezvisko: this.state.priezviskoKonatel,
                    datumNarodenia: this.state.datumNarodeniaFirma,
                    rodneCislo: this.state.rodneCisloFirma,
                }

            }

            var spolocnici = this.state.spolocnici;
            spolocnici.push(datas);


            this.setState({
                add: false,
                addkonatel: false,
                spolocnici: spolocnici,
            })

            event.preventDefault();

        }


    }

    handleEmail(event){
        this.setState({
            email: event.target.value,
        })
    }


    handleOsoba(event){
        this.setState({
            osoba: event.target.value
        })
    }


    handleFirmaSubmit(event){
        if(this.state.companyForm != "" && this.state.companyName != "") {
            this.setState({
                companyValidator: "Obchodné meno je voľné",
            });
            event.preventDefault();
        }
        else{
            event.preventDefault();
            NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
            return;
        }

    }

    handleFirmaName(event){
        this.setState({
            companyName: event.target.value,
        })
    }
    handleFirmaForm(event){
        this.setState({
            companyForm: event.target.value,
        })
    }

    render() {
        return (
            <div className="companyName">
                <NotificationContainer/>
                <ReactTooltip />

                <div className="container">
                    <div className="row ods">
                        <div className="col-md-12">
                            <h2 className="center">VYPLŇTE ÚDAJE PRE POKRAČOVANIE</h2>
                            <p className="center">Vyplňte základné imanie a pridajte zakladateľov (spoločníkov) a konateľov</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">

                            <form onSubmit={this.handleFirmaSubmit}>
                                <h3 className="nadpis">Základné imanie</h3>
                                <div className="form-row firmazvol">


                                    <div className="col-4 firmazvol">
                                        <label className="mobvyska"> Výška</label> <br/>
                                        <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." className="form-control" value={this.state.vyska} type="number" onChange={this.handleVyska} />
                                        <br/>
                                        <span className="error">{this.state.vyska2}</span>
                                    </div>
                                    <div className="col-4 firmazvol">
                                        <label> Rozsah splatenia</label> <br/>
                                        <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. V prípade jediného zakladateľa musí byť základné imanie splatené v plnom rozsahu, teda 5000 eur (100%). Ak je zakladateľov viac, rozsah splatenia základného imania postačí vo výške 2500 eur." className="form-control" value={this.state.rozsah} type="number" onChange={this.handleRozsah}  />
                                        <br/>
                                        <span className="error">{this.state.rozsah2}</span>
                                    </div>
                                </div>
                            </form>

                        </div>
                        <div className="col-md-12">
                            {this.state.companyValidator}
                        </div>
                    </div>
                </div>
                <div className="formular">
                    <div>
                        <div className="container-fluid blue">
                            <div className="container">


                                <div className="row">
                                    <div className="col-md-10">
                                        <h3 className="nadpis">Zakladatelia (Spoločníci) a konatelia</h3>

                                        {this.state.spolocnici.length > 0 ?

                                            <div className="spolocnici">

                                                <table className="table">
                                                    <thead>
                                                    <th>Meno a priezvisko</th>
                                                    <th>Spoločník / konateľ</th>
                                                    <th>Konateľ</th>


                                                    <th>Vklad</th>
                                                    <th>Podiel</th>
                                                    <th>Splatené</th>
                                                    <th>Správca vkladu</th>
                                                    <th>Upraviť</th>
                                                    <th>Zmazať</th>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.spolocnici.map((data,index)=> {
                                                        return data.osoba == "fyzicka" && (data.justkonatel == false || data.justkonatel == null ) ?

                                                            <tr>

                                                                <td>{data.meno} {data.priezvisko}</td>
                                                                <td>Spoločník</td>
                                                                <td>{data.konatel == true ?
                                                                    <span>Áno</span> : <span>Nie</span>}</td>


                                                                <td>{data.vyskaVkladu}€</td>
                                                                <td>{data.podielSpolocnost}%</td>
                                                                <td>{data.rozsahSplatenia}€</td>
                                                                <td>
                                                                    {data.spravcaVkladu == true ?
                                                                        <span>Áno</span> : <span>Nie</span>}
                                                                </td>

                                                                <td><a onClick={()=>this.handleEdit(index)}> <img src={Pencil}/> </a></td>
                                                                <td><a onClick={()=>this.handleDelete(index)}><img src={Delete}/> </a></td>


                                                            </tr>

                                                            : data.justkonatel == true ?

                                                            <tr>

                                                                <td>{data.meno} {data.priezvisko}</td>
                                                                <td>Konateľ</td>
                                                                <td>{data.konatel == true ?
                                                                    <span>Áno</span> : <span>Nie</span>}</td>


                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>-</td>
                                                                <td>
                                                                    {data.spravcaVkladu == true ?
                                                                        <span>Áno</span> : <span>Nie</span>}
                                                                </td>

                                                                <td><a onClick={()=>this.handleEdit(index)}> <img src={Pencil}/> </a></td>
                                                                <td><a onClick={()=>this.handleDelete(index)}><img src={Delete}/> </a></td>


                                                            </tr>
                                                                : <tr>

                                                                    <td>{data.obchodneMeno}</td>
                                                                    <td>Spoločník</td>
                                                                    <td>{data.konatel == true ?
                                                                        <span>Áno</span> : <span>Nie</span>}</td>
                                                                    <td>{data.vyskaVkladuFirma}€</td>
                                                                    <td>{data.podielSpolocnostFirma}%</td>
                                                                    <td>{data.rozsahSplateniaFirma}€</td>

                                                                    <td>{data.spravcaVkladu == true ?
                                                                        <span>Áno</span> : <span>Nie</span>}</td>
                                                                    <td><a onClick={()=>this.handleEdit(index)}> X</a></td>
                                                                    <td><a onClick={()=>this.handleDelete(index)}>X</a></td>


                                                                </tr>
                                                     })}

                                                    </tbody>

                                                </table>

                                                <div className="mobile row">
                                                    {this.state.spolocnici.map((data,index)=> {
                                                        return data.osoba == "fyzicka" ?


                                                                <div className="col-md-6">
                                                                <p>Meno a priezvisko: {data.meno} {data.priezvisko}</p>
                                                                    {data.justkonatel == true ?
                                                                    <p>Spoločník / konateľ: Konateľ</p>
                                                                        :  <p>Spoločník / konateľ: Spoločník</p>}

                                                                    {data.justkonatel == false || data.justkonatel == null ?
                                                                        <div>

                                                                <p>Výška vkladu: {data.vyskaVkladu}€</p>
                                                                <p>Podieľ: {data.podielSpolocnost}%</p>
                                                                <p>Rozsah splatenia: {data.rozsahSplatenia}€</p>
                                                                    <p>Správca vkladu:
                                                                        {data.spravcaVkladu == true ?
                                                                            <span>Áno</span> : <span>Nie</span>}
                                                                    </p>
                                                                        </div> : null}

                                                                <a onClick={()=>this.handleEdit(index)}> <img className="onhovpointer" src={Pencil}/> </a>
                                                                <a onClick={()=>this.handleDelete(index)}><img className="onhovpointer" src={Delete}/> </a>
                                                                    <br/>  <br/>
                                                                </div>




                                                            :

                                                            <div className="col-md-6">
                                                                <p>{data.obchodneMeno}</p>
                                                                <p>{data.spravcaVkladu == true ?
                                                                    <span>Áno</span> : <span>Nie</span>}</p>
                                                                <p>{data.konatel == true ?
                                                                    <span>Áno</span> : <span>Nie</span>}</p>
                                                                <p>{data.vyskaVkladuFirma}€</p>
                                                                <p>{data.podielSpolocnostFirma}%</p>
                                                                <p>{data.rozsahSplateniaFirma}€</p>
                                                                <a onClick={()=>this.handleEdit(index)}> <img className="onhovpointer" src={Pencil}/></a>
                                                                <a onClick={()=>this.handleDelete(index)}><img className="onhovpointer" src={Delete}/></a>
                                                                <br/>  <br/>


                                                            </div>
                                                    })}

                                                </div>


                                            </div>
                                            : null }


                                        <div className="form-group firmazvol">
                                            {this.state.add == false ?
                                            <a className="btn btn-primary white posunsa" onClick={this.addSpolocnik}>Pridať spoločníka</a>
                                                : null}
                                            {this.state.addkonatel == false ?
                                                <a className="btn btn-primary white" onClick={this.addKonatel}>Pridať konateľa</a>
                                                : null}
                                        </div>
                                        <span className="error">{this.state.email2}</span>

                                        {this.state.add == true ?
                                            <div>
                                                <form onSubmit={this.handleSubmit}>
                                                    <ReactTooltip />
                                                    <div className="form-group firmazvol">
                                                        <label>Typ zakladateľa *</label>
                                                        <select value={this.state.osoba} data-tip="Vložte zakladateľa (spoločníka), ktorým bude fyzická osoba alebo právnická osoba (napr. iná spoločnosť)." onChange={this.handleOsoba} className="form-control">
                                                            <option value="fyzicka">Fyzická osoba</option>
                                                            <option value="pravnicka">Právnická osoba</option>

                                                        </select>
                                                    </div>

                                                    { this.state.osoba == 'fyzicka' ?
                                                        <div className="fyzickaosoba">

                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-2">
                                                                    <br/>
                                                                    <label>Titul pred menom</label>
                                                                    <input placeholder="Ing." value={this.state.titulPredMenom} onChange={this.handleTitulPredMenom} type="text" className="form-control" />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <br/><label>Meno *</label>
                                                                    <input value={this.state.meno} onChange={this.handleMeno} type="text" className="form-control" />
                                                                    <span className="error">{this.state.meno2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <br/><label>Priezvisko *</label>
                                                                    <input value={this.state.priezvisko} onChange={this.handlePriezvisko} type="text" className="form-control" />
                                                                    <span className="error">{this.state.priezvisko2}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <br/><label>Titul za menom</label>
                                                                    <input placeholder="PhD." onChange={this.handleTitulZaMenom} value={this.state.titulZaMenom} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-8">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} value={this.state.ulica} type="text" className="form-control" />
                                                                    <span className="error">{this.state.ulica2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Súpisné číslo / orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" value={this.state.supCislo} onChange={this.handleSupCislo} className="form-control" />
                                                                    <span className="error">{this.state.supCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-8">
                                                                    <label>Obec *</label>
                                                                    <input value={this.state.obec} onChange={this.handleObec} type="text" className="form-control" />
                                                                    <span className="error">{this.state.obec2}</span>
                                                                </div>
                                                                <div className="col-4">
                                                                    <label>PSČ * (bez medzery napr. 04001)</label>
                                                                    <input value={this.state.psc} onChange={this.handlePsc} type="text" className="form-control" />
                                                                    <span className="error">{this.state.psc2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" value={this.state.stat} onChange={this.handleStat} type="text" className="form-control" />
                                                                    <span className="error">{this.state.stat2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodenie} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodenia} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodenie2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCislo} onChange={this.handleRodneCislo} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Typ dokladu totožnosti *</label>
                                                                    <select onChange={this.handleTotoznost} className="form-control">
                                                                        <option>Vyberte</option>
                                                                        <option>Občiansky preukaz</option>
                                                                        <option>Pas</option>
                                                                        <option>Iný doklad</option>
                                                                    </select>
                                                                    <span className="error">{this.state.totoznost2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Číslo dokladu totožnosti *</label>
                                                                    <input type="text" value={this.state.cisloTotoznosti} onChange={this.handleCisloTotoznosti} className="form-control" />
                                                                    <span className="error">{this.state.cisloTotoznosti2}</span>
                                                                </div>


                                                            </div>

                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Výška vkladu *</label>
                                                                    <input value={this.state.vyskaVkladu} data-tip="Minimálna výška vkladu každého spoločníka jednotlivo nemie byť nižšia ako 750 eur. V prípade jediného zakladateľa (spoločníka) sa výška vkladu musí rovnať výške základného imania (min. 5000 eur)." onChange={this.handleVyskaVladu} type="text" className="form-control" />
                                                                    <br/>
                                                                    <span className="error">{this.state.vyskaVkladu2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Podiel v spoločnosti *</label>
                                                                    <input data-tip="V prípade jediného spoločníka musí byť základné imanie splatené vo výške 100%." value={this.state.podielSpolocnost} onChange={this.handlePodielSpolocnost} type="text" className="form-control" />
                                                                    <span className="error">{this.state.podielSpolocnost2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Rozsah splatenia vkladu *</label>
                                                                    <input data-tip="V prípade dvoch a viac spoločníkov, musí byť splatený vklad každého spoločníka minimálne vo výške 30%. Celková hodnota splatených vkladov musí byť aspoň 2500 eur." value={this.state.rozsahSplatenia} onChange={this.handleRozsahSplatenia} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rozsahSplatenia2}</span>
                                                                </div>
                                                            </div>

                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-6">

                                                                    <input type="checkbox" checked={this.state.konatel} onChange={this.handleKonatel} className="form-control zakladatel" /> Tento zakladateľ (spoločník) bude aj konateľom <br/>
                                                                </div>

                                                                    <div className="col-md-6">
                                                                        <input type="checkbox"
                                                                               checked={this.state.spravcaVkladu}
                                                                               onChange={this.handleSpravcaVkladu}
                                                                               className="form-control zakladatel"/> Tento
                                                                        zakladateľ (spoločník) bude aj správcom
                                                                        vkladu <br/>
                                                                        <br/>
                                                                        <span
                                                                            className="error">{this.state.spravcaVkladu2}</span>
                                                                    </div>


                                                            </div>
                                                            <br/>
                                                            <br/>
                                                            { this.state.konatel == true ?
                                                                <div>
                                                            <h4 className="nadpis"> Údaje pre vypis z registra trestov</h4>
                                                            <br/>
                                                            Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:
                                                            <br/>
                                                            <br/>

                                                            <div className="registerTrestov">

                                                                <div className="form-row firmazvol">
                                                                    <div className="col-md-6">
                                                                        <label>Miesto narodenia *</label>
                                                                        <input type="text" className="form-control" value={this.state.miestoNarodenia} onChange={this.handleMiestoNarodenia} />
                                                                        <span className="error">{this.state.miestoNarodenia2}</span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Štátna príslušnosť *</label>
                                                                        <input type="text" className="form-control" value={this.state.statnaPrislusnost} onChange={this.handleStatnaPrislusnost} />
                                                                        <span className="error">{this.state.statnaPrislusnost2}</span>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="form-row firmazvol">

                                                                    <div className="col-md-6">
                                                                        <label>Rodné priezvisko</label>
                                                                        <input type="text" className="form-control" value={this.state.rodnePriezvisko} onChange={this.handleRodnePriezvisko} />
                                                                        <span className="error">{this.state.rodnePriezvisko2}</span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Predchádzajúce priezvisko</label>
                                                                        <input type="text" className="form-control" value={this.state.predchadzajucePriezvisko} onChange={this.handlePredchadzajucePriezvisko} />
                                                                        <span className="error">{this.state.predchadzajucePriezvisko2}</span>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="form-row firmazvol">

                                                                    <div className="col-md-6">
                                                                        <label>Meno otca *</label>
                                                                        <input type="text" className="form-control" value={this.state.menoOtca} onChange={this.handleMenoOtca} />
                                                                        <span className="error">{this.state.menoOtca2}</span>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <label>Priezvisko otca *</label>
                                                                        <input type="text" className="form-control" value={this.state.priezviskoOtca} onChange={this.handlePriezviskoOtca} />
                                                                        <span className="error">{this.state.priezviskoOtca2}</span>
                                                                    </div>
                                                                </div>
                                                                <br/>
                                                                <div className="form-row firmazvol">

                                                                    <div className="col-md-4">
                                                                        <label>Meno matky *</label>
                                                                        <input type="text" className="form-control" value={this.state.menoMatky} onChange={this.handleMenoMatky} />
                                                                        <span className="error">{this.state.menoMatky2}</span>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label>Priezvisko matky *</label>
                                                                        <input type="text" className="form-control" value={this.state.priezviskoMatky} onChange={this.handlePriezviskoMatky} />
                                                                        <span className="error">{this.state.priezviskoMatky2}</span>
                                                                    </div>
                                                                    <div className="col-md-4">
                                                                        <label>Rodné priezvisko matky *</label>
                                                                        <input type="text" className="form-control" value={this.state.rodnePriezviskoMatky} onChange={this.handleRodnePriezviskoMatky} />
                                                                        <span className="error">{this.state.rodnePriezviskoMatky2}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                </div>
                                                            : null }
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <input type="submit" value="Hotovo" className="btn btn-primary" />
                                                                </div>
                                                            </div>


                                                        </div>
                                                    :


                                                        <div className="pravnicka">

                                                            <ReactTooltip />
                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>IČO *</label>
                                                                    <input type="text" className="form-control" value={this.state.ico} onChange={this.handleIco} />
                                                                    <span className="error">{this.state.ico2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Obchodné meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.obchodneMeno} onChange={this.handleObchodneMeno} />
                                                                    <span className="error">{this.state.obchodneMeno2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." type="text" className="form-control" value={this.state.ulicaFirma} onChange={this.handleUlicaFirma} />
                                                                    <span className="error">{this.state.ulicaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Súpisné číslo / Orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" className="form-control" value={this.state.supisneCisloFirma} onChange={this.handleSupisneCisloFirma} />
                                                                    <span className="error">{this.state.supisneCisloFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Obec *</label>
                                                                    <input type="text" className="form-control" value={this.state.obecFirma} onChange={this.handleObecFirma} />
                                                                    <span className="error">{this.state.obecFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>PSČ *</label>
                                                                    <input type="text" className="form-control" value={this.state.pscFirma} onChange={this.handlePscFirma} />
                                                                    <span className="error">{this.state.pscFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" type="text" className="form-control" value={this.state.statFirma} onChange={this.handleStatFirma} />
                                                                    <span className="error">{this.state.statFirma2}</span>
                                                                </div>


                                                            </div>



                                                            <br/>
                                                            <br/>
                                                            <h4 className="nadpis"> Zastúpenie osobou (konateľ)</h4>
                                                            <br/>


                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.menoKonatel} onChange={this.handleMenoKonatel} />
                                                                    <span className="error">{this.state.menoKonatel2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Priezvisko *</label>
                                                                    <input type="text" className="form-control" value={this.state.priezviskoKonatel} onChange={this.handlePriezviskoKonatel} />
                                                                    <span className="error">{this.state.priezviskoKonatel2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodeniaFirma} placeholder="dd.mm.YYYY"  onChange={this.handleDatumNarodeniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodeniaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCisloFirma} onChange={this.handleRodneCisloFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCisloFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-4">
                                                                    <label>Výška vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.vyskaVkladuFirma} onChange={this.handleVyskaVkladuFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.vyskaVkladuFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Podiel v spoločnosti *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.podielSpolocnostFirma} onChange={this.handlePodielSpolocnostFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.podielSpolocnostFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Rozsah splatenia vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.rozsahSplateniaFirma} onChange={this.handleRozsahSplateniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rozsahSplateniaFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <div className="form-row firmazvol">
                                                                {this.state.isSpravcaVkladu == false ?
                                                                    <div className="col-md-6">
                                                                        <input type="checkbox"
                                                                               checked={this.state.spravcaVkladu}
                                                                               onChange={this.handleSpravcaVkladu}
                                                                               className="form-control zakladatel"/> Tento
                                                                        zakladateľ (spoločník) bude aj správcom
                                                                        vkladu <br/>
                                                                        <br/>
                                                                        <span
                                                                            className="error">{this.state.spravcaVkladu2}</span>
                                                                    </div>
                                                                : null}
                                                            </div>

                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <input type="submit" value="Hotovo" className="btn btn-primary" />
                                                                </div>
                                                            </div>


                                                        </div>







                                                    }
                                                </form>
                                            </div>
                                            : null }

                                        {this.state.addkonatel == true ?
                                            <div>
                                                <form onSubmit={this.handleSubmit}>
                                                    <ReactTooltip />
                                                    <div className="form-group firmazvol">
                                                        <label>Typ zakladateľa - fyzická osoba</label>
                                                        <br/>

                                                    </div>

                                                    { this.state.osoba == 'fyzicka' ?
                                                        <div className="fyzickaosoba">

                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-2">
                                                                    <br/>
                                                                    <label>Titul pred menom</label>
                                                                    <input placeholder="Ing." value={this.state.titulPredMenom} onChange={this.handleTitulPredMenom} type="text" className="form-control" />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <br/><label>Meno *</label>
                                                                    <input value={this.state.meno} onChange={this.handleMeno} type="text" className="form-control" />
                                                                    <span className="error">{this.state.meno2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <br/><label>Priezvisko *</label>
                                                                    <input value={this.state.priezvisko} onChange={this.handlePriezvisko} type="text" className="form-control" />
                                                                    <span className="error">{this.state.priezvisko2}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <br/><label>Titul za menom</label>
                                                                    <input placeholder="PhD." onChange={this.handleTitulZaMenom} value={this.state.titulZaMenom} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-8">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} value={this.state.ulica} type="text" className="form-control" />
                                                                    <span className="error">{this.state.ulica2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Súpisné číslo / orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" value={this.state.supCislo} onChange={this.handleSupCislo} className="form-control" />
                                                                    <span className="error">{this.state.supCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-8">
                                                                    <label>Obec *</label>
                                                                    <input value={this.state.obec} onChange={this.handleObec} type="text" className="form-control" />
                                                                    <span className="error">{this.state.obec2}</span>
                                                                </div>
                                                                <div className="col-4">
                                                                    <label>PSČ * (bez medzery napr. 04001)</label>
                                                                    <input value={this.state.psc} onChange={this.handlePsc} type="text" className="form-control" />
                                                                    <span className="error">{this.state.psc2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" value={this.state.stat} onChange={this.handleStat} type="text" className="form-control" />
                                                                    <span className="error">{this.state.stat2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodenie} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodenia} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodenie2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCislo} onChange={this.handleRodneCislo} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Typ dokladu totožnosti *</label>
                                                                    <select onChange={this.handleTotoznost} className="form-control">
                                                                        <option>Vyberte</option>
                                                                        <option>Občiansky preukaz</option>
                                                                        <option>Pas</option>
                                                                        <option>Iný doklad</option>
                                                                    </select>
                                                                    <span className="error">{this.state.totoznost2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Číslo dokladu totožnosti *</label>
                                                                    <input type="text" value={this.state.cisloTotoznosti} onChange={this.handleCisloTotoznosti} className="form-control" />
                                                                    <span className="error">{this.state.cisloTotoznosti2}</span>
                                                                </div>


                                                            </div>

                                                            <br/>

                                                            <br/>
                                                            <br/>
                                                            { this.state.konatel == true ?
                                                                <div>
                                                                    <h4 className="nadpis"> Údaje pre vypis z registra trestov</h4>
                                                                    <br/>
                                                                    Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:
                                                                    <br/>
                                                                    <br/>

                                                                    <div className="registerTrestov">

                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-6">
                                                                                <label>Miesto narodenia *</label>
                                                                                <input type="text" className="form-control" value={this.state.miestoNarodenia} onChange={this.handleMiestoNarodenia} />
                                                                                <span className="error">{this.state.miestoNarodenia2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Štátna príslušnosť *</label>
                                                                                <input type="text" className="form-control" value={this.state.statnaPrislusnost} onChange={this.handleStatnaPrislusnost} />
                                                                                <span className="error">{this.state.statnaPrislusnost2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-6">
                                                                                <label>Rodné priezvisko</label>
                                                                                <input type="text" className="form-control" value={this.state.rodnePriezvisko} onChange={this.handleRodnePriezvisko} />
                                                                                <span className="error">{this.state.rodnePriezvisko2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Predchádzajúce priezvisko</label>
                                                                                <input type="text" className="form-control" value={this.state.predchadzajucePriezvisko} onChange={this.handlePredchadzajucePriezvisko} />
                                                                                <span className="error">{this.state.predchadzajucePriezvisko2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-6">
                                                                                <label>Meno otca *</label>
                                                                                <input type="text" className="form-control" value={this.state.menoOtca} onChange={this.handleMenoOtca} />
                                                                                <span className="error">{this.state.menoOtca2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Priezvisko otca *</label>
                                                                                <input type="text" className="form-control" value={this.state.priezviskoOtca} onChange={this.handlePriezviskoOtca} />
                                                                                <span className="error">{this.state.priezviskoOtca2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-4">
                                                                                <label>Meno matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.menoMatky} onChange={this.handleMenoMatky} />
                                                                                <span className="error">{this.state.menoMatky2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Priezvisko matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.priezviskoMatky} onChange={this.handlePriezviskoMatky} />
                                                                                <span className="error">{this.state.priezviskoMatky2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Rodné priezvisko matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.rodnePriezviskoMatky} onChange={this.handleRodnePriezviskoMatky} />
                                                                                <span className="error">{this.state.rodnePriezviskoMatky2}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null }
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <input type="submit" value="Hotovo" className="btn btn-primary" />
                                                                </div>
                                                            </div>


                                                        </div>
                                                        :


                                                        <div className="pravnicka">

                                                            <ReactTooltip />
                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>IČO *</label>
                                                                    <input type="text" className="form-control" value={this.state.ico} onChange={this.handleIco} />
                                                                    <span className="error">{this.state.ico2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Obchodné meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.obchodneMeno} onChange={this.handleObchodneMeno} />
                                                                    <span className="error">{this.state.obchodneMeno2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." type="text" className="form-control" value={this.state.ulicaFirma} onChange={this.handleUlicaFirma} />
                                                                    <span className="error">{this.state.ulicaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Súpisné číslo / Orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" className="form-control" value={this.state.supisneCisloFirma} onChange={this.handleSupisneCisloFirma} />
                                                                    <span className="error">{this.state.supisneCisloFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Obec *</label>
                                                                    <input type="text" className="form-control" value={this.state.obecFirma} onChange={this.handleObecFirma} />
                                                                    <span className="error">{this.state.obecFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>PSČ *</label>
                                                                    <input type="text" className="form-control" value={this.state.pscFirma} onChange={this.handlePscFirma} />
                                                                    <span className="error">{this.state.pscFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" type="text" className="form-control" value={this.state.statFirma} onChange={this.handleStatFirma} />
                                                                    <span className="error">{this.state.statFirma2}</span>
                                                                </div>


                                                            </div>



                                                            <br/>
                                                            <br/>
                                                            <h4 className="nadpis"> Zastúpenie osobou (konateľ)</h4>
                                                            <br/>


                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.menoKonatel} onChange={this.handleMenoKonatel} />
                                                                    <span className="error">{this.state.menoKonatel2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Priezvisko *</label>
                                                                    <input type="text" className="form-control" value={this.state.priezviskoKonatel} onChange={this.handlePriezviskoKonatel} />
                                                                    <span className="error">{this.state.priezviskoKonatel2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodeniaFirma} placeholder="dd.mm.YYYY"  onChange={this.handleDatumNarodeniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodeniaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCisloFirma} onChange={this.handleRodneCisloFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCisloFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-4">
                                                                    <label>Výška vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.vyskaVkladuFirma} onChange={this.handleVyskaVkladuFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.vyskaVkladuFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Podiel v spoločnosti *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.podielSpolocnostFirma} onChange={this.handlePodielSpolocnostFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.podielSpolocnostFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Rozsah splatenia vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.rozsahSplateniaFirma} onChange={this.handleRozsahSplateniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rozsahSplateniaFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <div className="form-row firmazvol">
                                                                {this.state.isSpravcaVkladu == false ?
                                                                    <div className="col-md-6">
                                                                        <input type="checkbox"
                                                                               checked={this.state.spravcaVkladu}
                                                                               onChange={this.handleSpravcaVkladu}
                                                                               className="form-control zakladatel"/> Tento
                                                                        zakladateľ (spoločník) bude aj správcom
                                                                        vkladu <br/>
                                                                        <br/>
                                                                        <span
                                                                            className="error">{this.state.spravcaVkladu2}</span>
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <input type="submit" value="Hotovo" className="btn btn-primary" />
                                                                </div>
                                                            </div>


                                                        </div>







                                                    }
                                                </form>
                                            </div>
                                            : null }

                                        {this.state.edit == true ?

                                            <div>

                                                {this.state.edittype == "fyzicka" ?
                                                    <div>
                                                        <div className="fyzickaosoba">

                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-2">
                                                                    <br/>
                                                                    <label>Titul pred menom</label>
                                                                    <input value={this.state.titulPredMenom} placeholder="Ing." onChange={this.handleTitulPredMenom} type="text" className="form-control" />
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <br/><label>Meno *</label>
                                                                    <input value={this.state.meno} onChange={this.handleMeno} type="text" className="form-control" />
                                                                    <span className="error">{this.state.meno2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <br/><label>Priezvisko *</label>
                                                                    <input value={this.state.priezvisko} onChange={this.handlePriezvisko} type="text" className="form-control" />
                                                                    <span className="error">{this.state.priezvisko2}</span>
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <br/><label>Titul za menom</label>
                                                                    <input placeholder="PhD." onChange={this.handleTitulZaMenom} value={this.state.titulZaMenom} type="text" className="form-control" />
                                                                </div>
                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-8">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} value={this.state.ulica} type="text" className="form-control" />
                                                                    <span className="error">{this.state.ulica2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Súpisné číslo / orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" value={this.state.supCislo} onChange={this.handleSupCislo} className="form-control" />
                                                                    <span className="error">{this.state.supCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-8">
                                                                    <label>Obec *</label>
                                                                    <input value={this.state.obec} onChange={this.handleObec} type="text" className="form-control" />
                                                                    <span className="error">{this.state.obec2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>PSČ *</label>
                                                                    <input value={this.state.psc} onChange={this.handlePsc} type="text" className="form-control" />
                                                                    <span className="error">{this.state.psc2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" value={this.state.stat} onChange={this.handleStat} type="text" className="form-control" />
                                                                    <span className="error">{this.state.stat2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodenie} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodenia} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodenie2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCislo} onChange={this.handleRodneCislo} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCislo2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Typ dokladu totožnosti *</label>
                                                                    <select onChange={this.handleTotoznost} className="form-control">
                                                                        <option>Vyberte</option>
                                                                        <option>Občiansky preukaz</option>
                                                                        <option>Pas</option>
                                                                        <option>Iný doklad</option>
                                                                    </select>
                                                                    <span className="error">{this.state.totoznost2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Číslo dokladu totožnosti *</label>
                                                                    <input type="text" value={this.state.cisloTotoznosti} onChange={this.handleCisloTotoznosti} className="form-control" />
                                                                    <span className="error">{this.state.cisloTotoznosti2}</span>
                                                                </div>


                                                            </div>

                                                            <br/>

                                                            {this.state.editkonatel == false ?
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-4">
                                                                    <label>Výška vkladu *</label>
                                                                    <input value={this.state.vyskaVkladu} data-tip="Minimálna výška vkladu každého spoločníka jednotlivo nemie byť nižšia ako 750 eur. V prípade jediného zakladateľa (spoločníka) sa výška vkladu musí rovnať výške základného imania (min. 5000 eur)." onChange={this.handleVyskaVladu} type="text" className="form-control" />
                                                                    <br/>
                                                                    <span className="error">{this.state.vyskaVkladu2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Podiel v spoločnosti *</label>
                                                                    <input data-tip="V prípade jediného spoločníka musí byť základné imanie splatené vo výške 100%." value={this.state.podielSpolocnost} onChange={this.handlePodielSpolocnost} type="text" className="form-control" />
                                                                    <span className="error">{this.state.podielSpolocnost2}</span>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <label>Rozsah splatenia vkladu *</label>
                                                                    <input data-tip="V prípade dvoch a viac spoločníkov, musí byť splatený vklad každého spoločníka minimálne vo výške 30%. Celková hodnota splatených vkladov musí byť aspoň 2500 eur." value={this.state.rozsahSplatenia} onChange={this.handleRozsahSplatenia} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rozsahSplatenia2}</span>
                                                                </div>
                                                            </div>
                                                                : null }

                                                            {this.state.editkonatel == false ?

                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-6">

                                                                    <input type="checkbox" checked={this.state.konatel} onChange={this.handleKonatel} className="form-control zakladatel" /> Tento zakladateľ (spoločník) bude aj konateľom <br/>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input type="checkbox" checked={this.state.spravcaVkladu} onChange={this.handleSpravcaVkladu} className="form-control zakladatel" /> Tento zakladateľ (spoločník) bude aj správcom vkladu <br/>
                                                                    <br/>
                                                                    <span className="error">{this.state.spravcaVkladu2}</span>
                                                                </div>

                                                            </div>
                                                                : null }


                                                            <br/>
                                                            <br/>
                                                            { this.state.konatel == true ?
                                                                <div>
                                                                    <h4 className="nadpis"> Údaje pre vypis z registra trestov</h4>
                                                                    <br/>
                                                                    Aby mohol za vás živnostenský úrad online vyžiadať výpis z registra trestov konateľa, vyžaduje nasledovné údaje:
                                                                    <br/>
                                                                    <br/>

                                                                    <div className="registerTrestov">

                                                                        <div className="form-row firmazvol">
                                                                            <div className="col-md-6">
                                                                                <label>Miesto narodenia *</label>
                                                                                <input type="text" className="form-control" value={this.state.miestoNarodenia} onChange={this.handleMiestoNarodenia} />
                                                                                <span className="error">{this.state.miestoNarodenia2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Štátna príslušnosť *</label>
                                                                                <input type="text" className="form-control" value={this.state.statnaPrislusnost} onChange={this.handleStatnaPrislusnost} />
                                                                                <span className="error">{this.state.statnaPrislusnost2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-6">
                                                                                <label>Rodné priezvisko *</label>
                                                                                <input type="text" className="form-control" value={this.state.rodnePriezvisko} onChange={this.handleRodnePriezvisko} />
                                                                                <span className="error">{this.state.rodnePriezvisko2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Predchádzajúce priezvisko</label>
                                                                                <input type="text" className="form-control" value={this.state.predchadzajucePriezvisko} onChange={this.handlePredchadzajucePriezvisko} />
                                                                                <span className="error">{this.state.predchadzajucePriezvisko2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-6">
                                                                                <label>Meno otca *</label>
                                                                                <input type="text" className="form-control" value={this.state.menoOtca} onChange={this.handleMenoOtca} />
                                                                                <span className="error">{this.state.menoOtca2}</span>
                                                                            </div>
                                                                            <div className="col-md-6">
                                                                                <label>Priezvisko otca *</label>
                                                                                <input type="text" className="form-control" value={this.state.priezviskoOtca} onChange={this.handlePriezviskoOtca} />
                                                                                <span className="error">{this.state.priezviskoOtca2}</span>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="form-row firmazvol">

                                                                            <div className="col-md-4">
                                                                                <label>Meno matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.menoMatky} onChange={this.handleMenoMatky} />
                                                                                <span className="error">{this.state.menoMatky2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Priezvisko matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.priezviskoMatky} onChange={this.handlePriezviskoMatky} />
                                                                                <span className="error">{this.state.priezviskoMatky2}</span>
                                                                            </div>
                                                                            <div className="col-md-4">
                                                                                <label>Rodné priezvisko matky *</label>
                                                                                <input type="text" className="form-control" value={this.state.rodnePriezviskoMatky} onChange={this.handleRodnePriezviskoMatky} />
                                                                                <span className="error">{this.state.rodnePriezviskoMatky2}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : null }
                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <a className="btn btn-primary" onClick={this.saveedit}>Uložiť</a>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    : null }

                                                {this.state.edittype == "pravnicka" ?
                                                    <div>
                                                        <div className="pravnicka">

                                                            <ReactTooltip />
                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>IČO *</label>
                                                                    <input type="text" className="form-control" value={this.state.ico} onChange={this.handleIco} />
                                                                    <span className="error">{this.state.ico2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Obchodné meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.obchodneMeno} onChange={this.handleObchodneMeno} />
                                                                    <span className="error">{this.state.obchodneMeno2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Ulica/Námestie *</label>
                                                                    <input data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." type="text" className="form-control" value={this.state.ulicaFirma} onChange={this.handleUlicaFirma} />
                                                                    <span className="error">{this.state.ulicaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Súpisné číslo / Orientačné číslo *</label>
                                                                    <input data-tip="Zadajte orientačné číslo prípadne aj súpisné číslo podľa adresy bydliska uvedenej vo Vašom doklade totožnosti (občiansky preukaz)." type="text" className="form-control" value={this.state.supisneCisloFirma} onChange={this.handleSupisneCisloFirma} />
                                                                    <span className="error">{this.state.supisneCisloFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Obec *</label>
                                                                    <input type="text" className="form-control" value={this.state.obecFirma} onChange={this.handleObecFirma} />
                                                                    <span className="error">{this.state.obecFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>PSČ *</label>
                                                                    <input type="text" className="form-control" value={this.state.pscFirma} onChange={this.handlePscFirma} />
                                                                    <span className="error">{this.state.pscFirma2}</span>
                                                                </div>

                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Štát *</label>
                                                                    <input placeholder="skratka štátu napr. SR" type="text" className="form-control" value={this.state.statFirma} onChange={this.handleStatFirma} />
                                                                    <span className="error">{this.state.statFirma2}</span>
                                                                </div>


                                                            </div>



                                                            <br/>
                                                            <br/>
                                                            <h4 className="nadpis"> Zastúpenie osobou (konateľ)</h4>
                                                            <br/>


                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Meno *</label>
                                                                    <input type="text" className="form-control" value={this.state.menoKonatel} onChange={this.handleMenoKonatel} />
                                                                    <span className="error">{this.state.menoKonatel2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Priezvisko *</label>
                                                                    <input type="text" className="form-control" value={this.state.priezviskoKonatel} onChange={this.handlePriezviskoKonatel} />
                                                                    <span className="error">{this.state.priezviskoKonatel2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-6">
                                                                    <label>Dátum narodenia *</label>
                                                                    <input value={this.state.datumNarodeniaFirma} placeholder="dd.mm.YYYY" onChange={this.handleDatumNarodeniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.datumNarodeniaFirma2}</span>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <label>Rodné číslo *</label>
                                                                    <input value={this.state.rodneCisloFirma} onChange={this.handleRodneCisloFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rodneCisloFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <br/>

                                                            <div className="form-row firmazvol">

                                                                <div className="col-md-4">
                                                                    <label>Výška vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.vyskaVkladuFirma} onChange={this.handleVyskaVkladuFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.vyskaVkladuFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Podiel v spoločnosti *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.podielSpolocnostFirma} onChange={this.handlePodielSpolocnostFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.podielSpolocnostFirma2}</span>
                                                                </div>

                                                                <div className="col-md-4">
                                                                    <label>Rozsah splatenia vkladu *</label>
                                                                    <input data-tip="Od 1.1.2016 finančné prostriedky do výšky 5000 eur nemusia byť vkladané ná účet v banke. Minimálna výška základného imania pri s.r.o. je podľa zákona 5000,- Eur. Môžete zadať aj viac." value={this.state.rozsahSplateniaFirma} onChange={this.handleRozsahSplateniaFirma} type="text" className="form-control" />
                                                                    <span className="error">{this.state.rozsahSplateniaFirma2}</span>
                                                                </div>


                                                            </div>
                                                            <div className="form-row firmazvol">
                                                                <div className="col-md-6">
                                                                    <input type="checkbox" checked={this.state.spravcaVkladu} onChange={this.handleSpravcaVkladu} className="form-control zakladatel" /> Tento zakladateľ (spoločník) bude aj správcom vkladu <br/>
                                                                    <br/>
                                                                    <span className="error">{this.state.spravcaVkladu2}</span>
                                                                </div>
                                                            </div>

                                                            <br/>
                                                            <div className="form-row firmazvol">
                                                                <div className="form-group">
                                                                    <a className="btn btn-primary" onClick={this.saveedit}>Uložiť</a>
                                                                </div>
                                                            </div>


                                                        </div>

                                                    </div>
                                                    : null }

                                            </div>

                                        : null}






                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="container">
                            <div className="row ods">
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="col-8">
                                            <label>KONEČNÝM UŽÍVATEĽOM VÝHOD SÚ</label>
                                            <br/>
                                            <input name="uzivatelVyhod" onChange={this.handleUzivatelVyhod} type="radio" checked={this.state.uzivatelVyhod == 'zakladatelia' ? true : false} value="zakladatelia" /> Spoločníci/zakladatelia <br/>
                                            <input name="uzivatelVyhod" onChange={this.handleUzivatelVyhod} type="radio" checked={this.state.uzivatelVyhod == 'ine-osoby' ? true : false} value="ine-osoby" /> Iné osoby <br/><br/>
                                            {this.state.uzivatelVyhod == 'ine-osoby' ?
                                                <textarea value={this.state.uzivatelVyhodText} onChange={this.handleUzivatelVyhodText} className="form-control"
                                                    placeholder="Uveďte mená a priezviská, adresu bydliska, dátum narodenia, rodné číslo, číslo pasu alebo občianskeho preukazu."></textarea>
                                            : null}
                                        </div>
                                    </div>
                                    <div className="form-row ods">
                                        <div className="col-8">
                                            <label>SPÔSOB KONANIA KONATEĽOV</label>
                                            <br/>
                                            <input name="konanieKonatelov" onChange={this.handleKonanieKonatelov} type="radio" checked={this.state.konanieKonatelov == 'samostatne' ? true : false} value="samostatne" /> V mene spoločnosti koná a podpisuje každý konateľ samostatne <br/>

                                            <input name="konanieKonatelov" onChange={this.handleKonanieKonatelov} type="radio" checked={this.state.konanieKonatelov == 'spolocne' ? true : false} value="spolocne" /> V mene spoločnosti konajú všetci konatelia spoločne <br/>

                                            <input name="konanieKonatelov" onChange={this.handleKonanieKonatelov} type="radio" checked={this.state.konanieKonatelov == 'ine' ? true : false} value="ine" /> Iné <br/><br/>
                                            { this.state.konanieKonatelov == 'ine' ?
                                                <textarea value={this.state.konanieKonatelovText} onChange={this.handleKonanieKonatelovText} className="form-control">

                                                </textarea>
                                            : null}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <form onSubmit={this.handleSubmitForm}>
                            <div className="container">
                                <div className="row ods odsbot">
                                    <div className="col-md-12 centerize firmazvol">
                                        <input type="submit" className="btn btn-primary" value="Pokračovať" />
                                        <br/>
                                        <br/>
                                        <span className="error"> {this.state.notallowed}</span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        );
    }
}

export default CompanyInfo;
