import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import ReactTooltip from 'react-tooltip'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import Delete from "./delete.svg";




class CompanyName extends Component {

    constructor(props){
        super(props);
        this.state={
            companyName: "",
            companyForm: " s.r.o.",
            companyValidator: "",
            email: "",
            email2: "",
            sidlo: "vlastne",
            ulica: "",
            psc: "",
            supisneCislo: "",
            orientacneCislo: "",
            obec: "",
            ulica2: "",
            psc2: "",
            supisneCislo2: "",
            orientacneCislo2: "",
            obec2: "",
            nazov2: "",
            druh: "",
            vlastnik: "",
            program: "",

            image: new Array(),
            sucess: false,
            error: false,
            imagePreviewUrl: false,
            validatedSro: "",
            validatedSro2: "",
            stat: "SR"

        }
        this.handleFirmaSubmit = this.handleFirmaSubmit.bind(this);
        this.handleFirmaName = this.handleFirmaName.bind(this);
        this.handleFirmaForm = this.handleFirmaForm.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handleSidlo = this.handleSidlo.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleStat = this.handleStat.bind(this);
        this.handleUlica = this.handleUlica.bind(this);
        this.handleSupisneCislo = this.handleSupisneCislo.bind(this);
        this.handleOrientacneCislo = this.handleOrientacneCislo.bind(this);
        this.handleObec = this.handleObec.bind(this);
        this.handlePsc = this.handlePsc.bind(this);
        this.handleVlastnik = this.handleVlastnik.bind(this);
        this.handleDruh = this.handleDruh.bind(this);
        this.handleProgram = this.handleProgram.bind(this);

        this.fileUpload = this.fileUpload.bind(this)
        this.overit = this.overit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.delimage = this.delimage.bind(this);
    }

    delimage(){
        this.setState({
            image: new Array(),
        })
    }
    handleStat(event){
        this.setState({
            stat: event.target.value,
        })
    }
    componentDidMount(){
        this.props.addlinka("one");
        var firstSubmit = localStorage.getItem("firstSubmit");
        if(firstSubmit != null && firstSubmit != 'proccess'){
            firstSubmit = JSON.parse(firstSubmit);

            console.log(firstSubmit);




            if(firstSubmit.lv == null){

                this.setState({

                    companyName: firstSubmit.nazov_spolocnosti,
                    companyForm: firstSubmit.slug_spolocnosti,
                    companyValidator: true,
                    email: firstSubmit.email,
                    sidlo: firstSubmit.sidlo,

                    ulica: firstSubmit.ulica,
                    psc: firstSubmit.psc,
                    supisneCislo: firstSubmit.supisneCislo,
                    orientacneCislo: firstSubmit.orientacneCislo,
                    obec: firstSubmit.obec,
                    druh: firstSubmit.druh,
                    vlastnik: firstSubmit.vlastnik,
                    program: firstSubmit.program,
                    image: new Array(),
                    stat: firstSubmit.stat,
                    validatedSro: true,


                })

            }
            else{
                this.setState({

                    companyName: firstSubmit.nazov_spolocnosti,
                    companyForm: firstSubmit.slug_spolocnosti,
                    companyValidator: true,
                    email: firstSubmit.email,
                    sidlo: firstSubmit.sidlo,

                    ulica: firstSubmit.ulica,
                    psc: firstSubmit.psc,
                    supisneCislo: firstSubmit.supisneCislo,
                    orientacneCislo: firstSubmit.orientacneCislo,
                    obec: firstSubmit.obec,
                    druh: firstSubmit.druh,
                    vlastnik: firstSubmit.vlastnik,
                    program: firstSubmit.program,
                    image: firstSubmit.lv,
                    stat: firstSubmit.stat,
                    validatedSro: true,


                })

            }

            console.log(firstSubmit.lv)

            if(firstSubmit.program == "XS"){

                var name1 = "Program - XS";
                var datas = {
                    name: name1,
                    price: Math.round(4*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(firstSubmit.program == "S"){

                var name1 = "Program - S";
                var datas = {
                    name: name1,
                    price: (6.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(firstSubmit.program == "M"){

                var name1 = "Program - M";
                var datas = {
                    name: name1,
                    price: Math.round(8.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(firstSubmit.program == "L"){

                var name1 = "Program - L";
                var datas = {
                    name: name1,
                    price: Math.round(13.90*12*100)/100,
                }

                this.props.addDoplnkove(datas);
            }
            else if(firstSubmit.program == "XL"){

                var name1 = "Program - XL";
                var datas = {
                    name: name1,
                    price: Math.round(19.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
        }
        else{
            localStorage.setItem("firstSubmit", "proccess");
        }
    }
    overit(){
        var url = "https://autoform.ekosystem.slovensko.digital/api/corporate_bodies/search?q=name:" + this.state.companyName + "&private_access_token=ffee01fe7a96e2eec732e50d56d07f9f51e7f30094afaebaea2a7bf55081c1911da6d3c56eeda1a3&filter=active";
        console.log(url);
        axios.get(url).then(data=>{
            console.log(data.data.length)
            if(data.data.length > 0){
                console.log(data.data);
                if(this.validateSro(data.data[0].name, this.state.companyName)){
                    this.setState({
                        validatedSro: false,
                        validatedSro2: "Obchodné meno nie je voľné!"
                    });

                }
                else{
                    this.setState({
                        validatedSro: true,
                        validatedSro2: "Super, obchodné meno je voľné!"

                    })
                }
            }
            else{
                this.setState({
                    validatedSro: true,
                    validatedSro2: "Super, obchodné meno je voľné!"

                })
            }
        });

    }

    onChange(e) {
        let files = e.target.files || e.dataTransfer.files;
        if (!files.length)
            return;
        this.createImage(files[0]);
    }
    createImage(file) {
        let reader = new FileReader();
        reader.onload = (e) => {
            console.log(e.target.result);

            var image = new Array();

            image.push({path: file.name, data: e.target.result})

            this.setState({
                image: image
            })
        };

        reader.readAsDataURL(file);

    }


    fileUpload = async () =>{
        const values = this.state.image
        var self = this;
        await axios.post('http://localhost:8888/insert',  values).then((res) => {
            if(res.data === '1'){
                self.setState( { sucess : true});
//window.location.replace("/dashboard");
            }else{
                self.setState( { errorInsert : true});
            }
        }).catch((e)=>{
            console.log(e);
            /*window.sessionStorage.clear();
            window.location.replace('/'); */
        });
    }



    handleVlastnik(event){
        this.setState({vlastnik: event.target.value})
    }

    handleDruh(event){
        this.setState({druh: event.target.value})
    }
    handleUlica(event){
        this.setState({
            ulica: event.target.value,
        })
    }
    handleSupisneCislo(event){
        this.setState({
            supisneCislo: event.target.value,
        })
    }
    handleOrientacneCislo(event){
        this.setState({
            orientacneCislo: event.target.value,
        })
    }
    handleObec(event){
        this.setState({
            obec: event.target.value,
        })
    }
    handlePsc(event){
        this.setState({
            psc: event.target.value,
        })
    }
    handleSidlo(event){
        this.setState({
            sidlo: event.target.value,
            program: 123,
        })
    }
    handleProgram(event){
        this.setState({program: event.target.value})
    }
    handleSubmit(event){
        if(this.state.validatedSro != true){
            NotificationManager.warning(null,"Názov spoločnosti už existuje alebo ste ho neoverili!",3000);
            event.preventDefault();
            return;
        }

        if(this.state.email == ""){
            NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
            this.setState({
                email2: "Toto pole je povinné",
            });
            event.preventDefault();
            return;
        }
        if(this.state.sidlo == ""){
            NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
            this.setState({nazov2: "Toto pole je povinné"})
        }
        if(this.state.sidlo == "vlastne"){
            if(this.state.psc == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({psc2: "Toto pole je povinné"});
                event.preventDefault();
                return;
            }
            if(this.state.obec == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({obec2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }
           
            if(this.state.orientacneCislo == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({orientacneCislo2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }
            if(this.state.ulica == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({ulica2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }
            if(this.state.druh == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({druh2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }
            if(this.state.vlastnik == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({vlastnik2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }
            if(this.state.stat == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({stat2: "Toto pole je povinné"})
                event.preventDefault();
                return;
            }


            var datas = {

                nazov_spolocnosti: this.state.companyName,
                slug_spolocnosti: this.state.companyForm,
                email: this.state.email,
                sidlo: this.state.sidlo,
                ulica: this.state.ulica,
                supisneCislo: this.state.supisneCislo,
                orientacneCislo: this.state.orientacneCislo,
                obec: this.state.obec,
                psc: this.state.psc,
                druh: this.state.druh,
                vlastnik: this.state.vlastnik,
                stat: this.state.stat,
                lv: this.state.image,

            }




            this.props.firstSubmit(datas);
            this.props.makeSecond();
            localStorage.setItem("firstSubmit", JSON.stringify(datas));
            event.preventDefault();
            return;

        }
        else if(this.state.sidlo == "firmaprefirmy"){
            if(this.state.email == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({
                    email2: "Toto pole je povinné",
                });
                event.preventDefault();
                return;
            }
            if(this.state.companyName == ""){
                NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
                this.setState({nazov2: "Toto pole je povinné"});
                event.preventDefault();
                return;
            }



            var datas = {
                nazov_spolocnosti: this.state.companyName,
                slug_spolocnosti: this.state.companyForm,
                sidlo: "firmaprefirmy",
                email: this.state.email,
                program: this.state.program
            }

            localStorage.setItem("firstSubmit", JSON.stringify(datas));

            this.props.firstSubmit(datas);


            if(this.state.program == "XS"){
                var name1 = "Program - XS";
                var datas = {
                    name: name1,
                    price: Math.round(4*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(this.state.program == "S"){
                var name1 = "Program - S";
                var datas = {
                    name: name1,
                    price: (6.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(this.state.program == "M"){
                var name1 = "Program - M";
                var datas = {
                    name: name1,
                    price: Math.round(8.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(this.state.program == "L"){
                var name1 = "Program - L";

                var datas = {
                    name: name1,
                    price: Math.round(13.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }
            else if(this.state.program == "XL"){
                var name1 = "Program - XL";
                var datas = {
                    name: name1,
                    price: Math.round(19.90*12*100)/100,
                }
                this.props.addDoplnkove(datas);
            }





            console.log(datas);
            this.props.makeSecond();

            event.preventDefault();
            return;




        }
    }

    handleEmail(event){
        this.setState({
            email: event.target.value,
        })
    }

    validateSro(string, companyname) {

        var n = string.includes("s.r.o.");
        var m = string.includes("s. r. o.");
        var x = string.includes("s r.o.");
        var ac = string.includes(", akciová spoločnosť");
        var bc = string.includes("a.s.");
        if(n == true || m ==true || x== true || ac == true || bc == true) {
            if(string.includes(", s.r.o.")){
                var str = string.replace(", s.r.o.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }
            if(string.includes(", s. r. o.")){
                var str = string.replace(", s. r. o.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }
            if(string.includes(", spol. s r.o.")){
                var str = string.replace(", spol. s r.o.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }
            if(string.includes(", a.s.")){
                var str = string.replace(", a.s.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }
            if(string.includes(", akciová spoločnosť")){
                var str = string.replace(", akciová spoločnosť", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }

            if(string.includes("s.r.o.")){
                var str = string.replace(" s.r.o.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }
            }
            if(string.includes("s. r. o.")){
                var str = string.replace(" s. r. o.", "");


                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    console.log(str);
                    console.log(companyname);

                    return false;
                }

            }
            if(string.includes("s r.o.")){
               var str =  string.replace(" s r.o.", "");
                console.log(str);
                console.log(companyname);
                if(str.toLowerCase() == companyname.toLowerCase()){
                    return true;
                }
                else{
                    return false;
                }

            }


            return true;
        }
        else{
            return false;
        }
    }


    handleFirmaSubmit(event){
        if(this.state.companyForm != "" && this.state.companyName != "") {
            this.setState({
                companyValidator: "Obchodné meno je voľné",
            });
            event.preventDefault();
        }
        else{
            event.preventDefault();
            NotificationManager.warning(null,"Nevyplnili ste všetký polia",3000);
            return;
        }

    }

    handleFirmaName(event){
        this.setState({
            companyName: event.target.value,
        })
    }
    handleFirmaForm(event){
        this.setState({
            companyForm: event.target.value,
        })
    }

    render() {
        return (
            <div className="companyName">
                <NotificationContainer/>




                <div className="container">
                    <div className="row ods">
                        <div className="col-md-12">
                            <h2 className="center">ZVOĽTE SI OBCHODNÉ MENO VAŠEJ NOVEJ FIRMY (SRO)</h2>
                            <p className="center">Zadajte údaje o vašej novej firme a všetko ostatné vybavíme do dvoch týždňov za vás</p>
                            <p className="center">Prosíme použivajte diakritiku, keďže dokumenty budú generované automaticky podľa vyplnenia formulára, ďakujeme</p>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            <ReactTooltip />

                                <div class="form-row firmazvol">
                                    <div className="col-md-8">
                                        <input onChange={this.handleFirmaName} placeholder="Obchodné meno spoločnosti *" value={this.state.companyName}  data-tip="Obchodné meno nemôže byť totožné s iným obchodným menom, ktoré už existuje. Odlíšenie iba právnou formou podnikania - dodatkom (s.r.o., a.s. a pod.) nepostačuje." type="text" className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                        <select onChange={this.handleFirmaForm} className="form-control">
                                            <option selected={this.state.companyForm == " s.r.o." ? true : false} value=" s.r.o.">s.r.o.</option>
                                            <option selected={this.state.companyForm == ", s.r.o." ? true : false} value=", s.r.o.">, s.r.o.</option>
                                            <option selected={this.state.companyForm == " spol. s.r.o." ? true : false} value=" spol. s.r.o.">spol. s.r.o.</option>
                                        </select>

                                    </div>
                                    <div className="col-md-2">
                                        <input type="submit" onClick={this.overit} value="Overiť"  className="btn btn-primary" />
                                    </div>


                                    <span className="error">{this.state.nazov2}</span>
                                    {this.state.validatedSro == false ?
                                        <span className="error">{this.state.validatedSro2}</span>
                                    : <span className="success">{this.state.validatedSro2}</span>}
                                </div>


                        </div>
                        <div className="col-md-12">
                            {this.state.companyValidator}
                        </div>
                    </div>
                </div>
                <div className="formular">
                    <form onSubmit={this.handleSubmit}>
                        <div className="container-fluid blue">
                                <div className="container">

                                    <div className="row">
                                        <div className="col-md-8">
                                            <h3 className="nadpis">Váš email</h3>
                                            <div className="form-group firmazvol">
                                                <input className="form-control" value={this.state.email} type="email" onChange={this.handleEmail} placeholder="@" />
                                            </div>
                                            <span className="error">{this.state.email2}</span>
                                            <div className="sidloSpolocnosti">
                                                <h3 className="nadpis">Sídlo spoločnosti</h3>
                                                <div className="form-group">
                                                    <input type="radio" onChange={this.handleSidlo} value="vlastne" name="sidlo" checked={this.state.sidlo == "vlastne"} /> Vlastné alebo prenajaté <br/>
                                                    <input type="radio" onChange={this.handleSidlo} value="firmaprefirmy" name="sidlo" checked={this.state.sidlo == "firmaprefirmy"} /> Zabezpečiť virtuálnu adresu cez <a target="_blank" href="https://www.firmaprefirmy.sk"> firmaprefirmy.sk</a> <br/>
                                                </div>
                                                {this.state.sidlo == "vlastne" ?

                                                    <div className="vlastneSidlo firmazvol">
                                                        <div class="form-row firmazvol">
                                                            <div class="col-md-6">
                                                                <label for="ulica" /> Ulica* <br/>
                                                                <input value={this.state.ulica} data-tip="Zadajte názov ulice alebo verejného priestranstva (námestie). Ak sa obec nečlení na ulice, zadajte do tohto poľa názov obce." onChange={this.handleUlica} className="form-control" required />
                                                                {this.state.ulica2}
                                                            </div>
                                                            <div class="col-md-3">
                                                                <label for="ulica" /> Súpisné číslo <br/>
                                                                <input data-tip="Pole súpisné číslo je nepovinné" value={this.state.supisneCislo} onChange={this.handleSupisneCislo} className="form-control" required />
                                                                {this.state.supisneCislo2}
                                                            </div>

                                                            <div class="col-md-3">
                                                                <label for="ulica" /> Orientačné číslo* <br/>
                                                                <input data-tip="Ide o číslo, ktoré sa na budovách uvádza zvyčajne červenou farbou. V adrese uvedenej v doklade totožnosti sa uvádza za lomítkom. Ak sa obec nedelí na ulice, orientačné číslo neexistuje - V takomto prípade uveďte aj do tohto poľa súpisné číslo." value={this.state.orientacneCislo} onChange={this.handleOrientacneCislo} className="form-control" required />
                                                                {this.state.orientacneCislo2}
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div class="form-row firmazvol">
                                                            <div class="col-md-6">
                                                                <label for="ulica" /> Obec* <br/>
                                                                <input value={this.state.obec} onChange={this.handleObec} className="form-control" required />
                                                                {this.state.obec2}
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="ulica" /> PSČ* (bez medzery napr. 04001)<br/>
                                                                <input value={this.state.psc} onChange={this.handlePsc} className="form-control" pattern="[0-9][0-9][0-9][0-9][0-9]" required/>
                                                                {this.state.psc2}
                                                            </div>
                                                        </div>
                                                        <br/>
                                                        <div class="form-row firmazvol">

                                                            <div class="col-md-6">
                                                                <label for="ulica" /> Štát*<br/>
                                                                <input value={this.state.stat} onChange={this.handleStat} className="form-control" placeholder="skratka štátu napr. SR" required/>
                                                                {this.state.psc2}
                                                            </div>
                                                        </div>

                                                        <br/>
                                                        <p>Na vytvorenie dokumentu "Súhlas vlastníka nehnuteľnosti" so zápisom nehnuteľnosti do obchodného registra ako sídla vašej spoločnosti budeme potrebovať nasledovné údaje:</p>

                                                        <div class="form-row firmazvol">
                                                            <div class="col-md-6">
                                                                <label for="ulica" /> Druh priestoru <br/>
                                                                <select name="ulica" onChange={this.handleDruh} className="form-control">
                                                                    <option value="">Zvoľte</option>
                                                                    <option selected={this.state.druh == 'Nebytový priestor' ? true : false} value="Nebytový priestor">Nebytový priestor</option>
                                                                    <option selected={this.state.druh == 'Byt v bytovom dome' ? true : false} value="Byt v bytovom dome">Byt v bytovom dome</option>
                                                                    <option selected={this.state.druh == 'Iná budova' ? true : false} value="Iná budova">Iná budova</option>
                                                                    <option selected={this.state.druh == 'Rodinný dom' ? true : false} value="Rodinný dom">Rodinný dom</option>
                                                                    <option selected={this.state.druh == 'Samostatne stojaca garáž' ? true : false} value="Samostatne stojaca garáž">Samostatne stojaca garáž</option>
                                                                </select>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label for="ulica" /> Vlastník priestoru <br/>
                                                                <input data-tip="Zadajte názov vlastníka nehnuteľnosti v ktorej bude mať Vaša firma sídlo (ak je vlastníkom spoločnosť) alebo titul, meno a priezvisko vlastníka nehnuteľnosti (ak je vlastníkom fyzická osoba). Ak je spoluvlastníkov viac, uveďte iba jedného z nich. Ostatných portál vyhľadá automaticky sám." className="form-control" value={this.state.vlastnik} onChange={this.handleVlastnik} required/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <br/>
                                                                <label className="label_imagem_artigo "> List vlastníctva: (slúži pre urýchlenie procesu) </label>
                                                                <input className="input_imagem_artigo namesub" type="file"  onChange={this.onChange} />

                                                                {this.state.image.map(data=>
                                                                    <p>{data.path} <a onClick={this.delimage}>&nbsp; <img width="15" src={Delete}/></a></p>
                                                                )}


                                                            </div>
                                                        </div>

                                                        <br/><br/>

                                                        <h3 className="nadpis">Zľavový kupón</h3>

                                                        <div className="form-group">
                                                            <input type="text" className="form-control" />
                                                        </div>

                                                    </div>

                                                    : <div>
                                                        <div className="form-group firmazvol">

                                                            {/*<select className="form-control" onChange={this.handleProgram}>*/}
                                                                {/*<option>Zvoľte</option>*/}
                                                                {/*<option selected={this.state.program == 'XS' ? true : false} value="XS">Program XS - +4,00€ mesačne</option>*/}
                                                                {/*<option selected={this.state.program == 'S' ? true : false} value="S">Program S - +6,90€ mesačne</option>*/}
                                                                {/*<option selected={this.state.program == 'M' ? true : false} value="M">Program M - +8,90€ mesačne</option>*/}
                                                                {/*<option selected={this.state.program == 'L' ? true : false} value="L">Program L - +13,90€ mesačne</option>*/}
                                                                {/*<option selected={this.state.program == 'XL' ? true : false} value="XL">Program XL - +19,90€ mesačne</option>*/}

                                                            {/*</select>*/}
                                                            <br/>
                                                            <a href="https://www.firmaprefirmy.sk/cennik" className="btn btn-primary" target="_blank">Cenník</a>
                                                        </div>
                                                    </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                        </div>

                        <div className="container">
                            <div className="row ods odsbot">
                                <div className="col-md-12 centerize firmazvol">
                                    <input type="submit" className="btn btn-primary" value="Pokračovať" />

                                </div>
                            </div>
                        </div>
                    </form>
            </div>

            </div>
        );
    }
}

export default CompanyName;
