import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import CompanyName from "./CompanyName";
import CompanyInfo from "./CompanyInfo";
import CompanyThird from "./CompanyThird";
import CompanyReview from "./CompanyReview";
import CompanyFinish from "./CompanyFinish";
import Logo from "./logo_firmy.svg";
import CookieConsent from "react-cookie-consent";




class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      first: true,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        firstSubmit: "",
        secondSubmit: "",
        thirdSubmit: "",
        fourthSubmit: "",
        price: 239,
        doplnkove: new Array(),
        zivnostiCena: 0,
        show: true,
        linka: "",

    }






    this.makeFirst = this.makeFirst.bind(this);
      this.makeSecond = this.makeSecond.bind(this);
      this.makeThird = this.makeThird.bind(this);
      this.makeFourth = this.makeFourth.bind(this);
      this.firstSubmit = this.firstSubmit.bind(this);
      this.fourthSubmit = this.fourthSubmit.bind(this);
      this.makeFifth = this.makeFifth.bind(this);
      this.thirdSubmit = this.thirdSubmit.bind(this);
      this.secondSubmit= this.secondSubmit.bind(this);
      this.addDoplnkove=this.addDoplnkove.bind(this);
      this.addZivnostCena = this.addZivnostCena.bind(this);
      this.closeCena = this.closeCena.bind(this);
      this.showCena = this.showCena.bind(this);
      this.one = this.one.bind(this);
      this.two = this.two.bind(this);
      this.three = this.three.bind(this);
      this.four = this.four.bind(this);
      this.addlinka = this.addlinka.bind(this);

  }


    addlinka(val){
        this.setState({
            linka: val,
        })
    }
    componentDidMount(){
        window.scrollTo(0, 0);
        if(window.innerWidth < 10000){
            this.setState({show: false});
        }

        var thirdSubmit = localStorage.getItem("thirdSubmit");
        console.log(thirdSubmit);

        if(thirdSubmit != null && thirdSubmit != 'proccess'){
            thirdSubmit = JSON.parse(thirdSubmit);

            var doplnkove = 0;

            thirdSubmit.forEach(function (e) {
                if(e.type != 'Voľná živnosť'){
                    doplnkove = doplnkove + e.price;
                }

            }.bind(this));

            this.setState({
                zivnostiCena: doplnkove,
            })

        }
    }
    closeCena(){
      this.setState({
          show:false,
      })
    }
    showCena(){
        this.setState({
            show:true,
        })
    }
    addZivnostCena(cena){
        this.setState({zivnostiCena: cena});
    }

    thirdSubmit(args){
      this.setState({
          thirdSubmit: args,
      })
    }

  fourthSubmit(args){
      this.setState({
          fourthSubmit: args,
      });
      console.log(args);
  }

  addDoplnkove(array){
    console.log(array);


      var doplnkove = new Array();

      doplnkove.push(array);

      console.log(doplnkove);
      this.setState({doplnkove: doplnkove});

  }
  makeFirst(){
    this.setState({
        first: true,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
    })
  }

    makeFifth(){
        this.setState({
            first: false,
            second: false,
            third: false,
            fourth: false,
            fifth: true,
        })
    }

  firstSubmit(args){
      this.setState({
          firstSubmit: args,
      });

  }

  secondSubmit(args){
      this.setState({
          secondSubmit: args,
      });

  }

    makeSecond(){
        this.setState({
            first: false,
            second: true,
            third: false,
            fourth: false,
            fifth: false,
        })
    }

    makeThird(){
        this.setState({
            first: false,
            second: false,
            third: true,
            fourth: false,
            fifth: false,
        })
    }

    makeFourth(){
        this.setState({
            first: false,
            second: false,
            third: false,
            fourth: true,
            fifth: false,
        })
    }
    one(){
      if(localStorage.getItem("firstSubmit") != null) {
          this.setState({
              first: true,
              second: false,
              third: false,
              fourth: false,
              fifth: false,
          })
      }
    }
    two(){
      if(localStorage.getItem("secondSubmit") != null) {
          this.setState({
              first: false,
              second: true,
              third: false,
              fourth: false,
              fifth: false,
          })
      }
    }
    three(){
      if(localStorage.getItem("thirdSubmit") != null) {
          this.setState({
              first: false,
              second: false,
              third: true,
              fourth: false,
              fifth: false,
          })
      }
    }
    four(){
      if(localStorage.getItem("fourthSubmit")!= null) {
          this.setState({
              first: false,
              second: false,
              third: false,
              fourth: true,
              fifth: false,
          })
      }
    }



  render() {
    return (

      <div className="App">
          <div className="container-fluid navko" >
              <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <nav className="navbar navbar-expand-xl navbar-light ">
                          <a className="navbar-brand" href="https://sro.firmaprefirmy.sk"><img width="200" className="logo lazyloading" src={Logo} data-was-processed="true" /> </a>
                          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                          <div className="collapse navbar-collapse" id="navbarNavDropdown">
                              <ul className="navbar-nav ml-auto">
                                  <div className="menu-menu-1-container">
                                      <ul id="primary-menu" className="menu">
                                          <li className="item btn btn-primary"><a target="_blank" href="https://www.firmaprefirmy.sk">firmaprefirmy.sk</a></li>

                                      </ul>
                                  </div>
                              </ul>
                          </div>
                      </nav>
                  </div>
                  </div>
              </div>
          </div>
          <div className="main">

          <div className="panel">
              <div className="navigation">
                  <a className={`${localStorage.getItem("firstSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'one' ? " active" : ""}`} onClick={this.one}>1. Obchodné meno</a>
                  <a className={`${localStorage.getItem("secondSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'two' ? " active" : ""}`} onClick={this.two}>2. Spoločníci</a>
                  <a className={`${localStorage.getItem("thirdSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'three' ? " active" : ""}`} onClick={this.three}>3. Predmety podnikania</a>
                  <a className={`${localStorage.getItem("fourthSubmit") != null ? "btn btn-allowed" : "btn"}${this.state.linka == 'four' ? " active" : ""}`} onClick={this.four}>4. Dokončenie</a>
                <br/><br/>
              </div>
              {this.state.first == true ?
              <CompanyName addlinka={this.addlinka} addDoplnkove={this.addDoplnkove} firstSubmit={this.firstSubmit} makeSecond={this.makeSecond} />
                  : null}

              {this.state.second == true ?
                  <CompanyInfo addlinka={this.addlinka} secondSubmit={this.secondSubmit} makeThird={this.makeThird} />
                  : null}

              {this.state.third == true ?
                  <CompanyThird addlinka={this.addlinka} spolocnici={this.state.secondSubmit.spolocnici} addZivnostCena={this.addZivnostCena} thirdSubmit={this.thirdSubmit} makeFourth={this.makeFourth} />
                  : null}

              {this.state.fourth == true ?
                  <CompanyReview addlinka={this.addlinka} price={this.state.price} doplnkove={this.state.doplnkove} zivnostiCena={this.state.zivnostiCena} firstSubmit={this.state.firstSubmit} secondSubmit={this.state.secondSubmit} thirdSubmit={this.state.thirdSubmit} fourthSubmit={this.fourthSubmit} makeFifth={this.makeFifth}/>
                  : null}

              {this.state.fifth == true ?
                  <CompanyFinish firstSubmit={this.state.firstSubmit} secondSubmit={this.state.secondSubmit} thirdSubmit={this.state.thirdSubmit} fourthSubmit={this.state.fourthSubmit} />
                  : null}
          </div>







          <div className="container-fluid black"><div className="container"><div className="row"><div className="col-md-6 ll1">firmapreludi.sk Copyright © 2019</div><div className="col-md-6 rr1">made by <a className="wiht" href="https://www.btect.sk"> BTect</a></div></div></div></div>
          </div>

          <CookieConsent
              location="bottom"
              buttonText="Súhlasím"
              cookieName="myAwesomeCookieName2"
              style={{ background: "#2B373B", fontSize: "12px!important" }}
              buttonStyle={{ color: "white", background: "#007bff", fontSize: "13px", textTransform: "uppercase", fontWeight: "700" }}
              expires={150}
          >
              Tieto stránky využívajú cookies. Ich ďalším používaním súhlasíte využívaním cookies. Prečítajte si <a href="https://www.firmaprefirmy.sk/ochrana-sukromia" target="_blank">informácie</a> o tom, ako cookies používame a ako ich používanie môžete odmietnuť nastavením svojho internetového prehliadača.
          </CookieConsent>

          </div>



    );
  }
}

export default App;
