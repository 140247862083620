import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import ReactTooltip from 'react-tooltip'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

var json = '[{"firstSubmit":{"nazov_spolocnosti":"asdfadfasdf","slug_spolocnosti":"s.r.o.","email":"asdf@adsfa.adsf","sidlo":"vlastne","ulica":"Benadova","supisneCislo":"901","orientacneCislo":"8","obec":"Kosice","psc":"04022","druh":"Nebytový priestor","vlastnik":"Bohuslav Potocnak","lv":""},"secondSubmit":{"imanie":5000,"splatene":5000,"spolocnici":[{"osoba":"fyzicka","titul":"","meno":"Bohuslav","priezvisko":"Potocnak","titul2":"","ulica":"Benadova","supCislo":"901/8","obec":"Kosice","psc":"04022","stat":"Slovensko","datumNarodenia":"24.06.1996","rodneCislo":"960624/4406","typDokladuTotoznosti":"Občiansky preukaz","cisloDokladuTotoznosti":"EP123123","vyskaVkladu":5000,"podielSpolocnost":100,"rozsahSplatenia":5000,"konatel":true,"spravcaVkladu":true,"registerTrestov":{"miestoNarodenia":"Kosice","statnaPrislusnost":"Slovenska","rodnePriezvisko":"Potocnak","predchadzajucePriezvisko":"","menoOtca":"Bohuslav","priezviskoOtca":"Potocnak","menoMatky":"Martina","priezviskoMatky":"Potocnakova","rodnePriezviskoMatky":"Petrulakova"}}],"uzivatelVyhod":"zakladatelia","konanieKonatelov":"samostatne","uzivatelVyhodText":"","konanieKonatelovText":""},"thirdSubmit":[{"name":"Reklamné a marketingové služby, prieskum trhu a verejnej mienky","price":0,"type":"Voľná živnosť"},{"name":"Počítačové služby a služby súvisiace s počítačovým spracovaním údajov","price":0,"type":"Voľná živnosť"}],"fourthSubmit":{"email":"","password":"","phone":"","danovy":"true","send":true,"dozvedeli":"","zlava":"","poznamka":""}}]';
json = JSON.parse(json);


class CompanyReview extends Component{
    constructor(props){
        super(props);
        this.state = {
            danovy: false,
            billing: false,
            send: false,
            registration_date: "",
            email: "",
            password: "",
            passwordRepeat: "",
            phone: "",
            poznamka: "",
            odporucanie: "",
            zlava: "",
            gdpr: false,
            vop: false,
            ok: false,
            gdpr2: "",
            vop2: "",
            ok2: "",
            platba: "prevod",
            json: new Array(),
            price: "",
            zivnostiCena: "",
            papiere: false,
            doplnkove: new Array(),
            allprice: 0,
            phone: "",
            phone2: "",
            billing_name: "",
            billing_name2: "",
            billing_lastname: "",
            billing_lastname2:"",
            billing_postcode: "",
            billing_postcode2:"",
            billing_street: "",
            billing_street2:"",
            billing_street_number: "",
            billing_street_number2: "",
            billing_city: "",
            billing_city2: "",
            billing_company_name: "",
            billing_ico: "",
            billing_dic: "",
            billing_icdph: "",
            x:0,
            platcasro: false,

        }

        this.increment = this.increment.bind(this);
        this.handlePlatba = this.handlePlatba.bind(this);
        this.handleDanovy = this.handleDanovy.bind(this);
        this.handlePosta = this.handlePosta.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePassword = this.handlePassword.bind(this);
        this.handlePasswordRepeat = this.handlePasswordRepeat.bind(this);
        this.phone = this.phone.bind(this);
        this.handleDozvedeli = this.handleDozvedeli.bind(this);
        this.handleKupon = this.handleKupon.bind(this);
        this.handlePoznamka = this.handlePoznamka.bind(this);
        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleGdpr = this.handleGdpr.bind(this);
        this.handleVop = this.handleVop.bind(this);
        this.handleAllOk = this.handleAllOk.bind(this);
        this.handleBillingIcDph = this.handleBillingIcDph.bind(this);
        this.handleBillingDic = this.handleBillingDic.bind(this);
        this.handleBillingIco = this.handleBillingIco.bind(this);
        this.handleBillingCompanyName = this.handleBillingCompanyName.bind(this);
        this.handleBillingPostcode = this.handleBillingPostcode.bind(this);
        this.handleBillingCity = this.handleBillingCity.bind(this);
        this.handleBillingStreetNumber = this.handleBillingStreetNumber.bind(this);
        this.handleBillingStreet = this.handleBillingStreet.bind(this);
        this.handleBillingLastname = this.handleBillingLastname.bind(this);
        this.handleBillingName = this.handleBillingName.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleDph = this.handleDph.bind(this);
        this.handlePapiere = this.handlePapiere.bind(this);

    }

    handlePapiere(){



        if(!this.state.papiere){
            this.setState({
                price: 59,
                allprice: this.state.allprice - 239 + 59,
            });
        }
        else{
            this.setState({
                price: 239,
                allprice: this.state.allprice - 59 + 239,
            });
        }

        this.setState({
            papiere: !this.state.papiere,
        });

    }

    increment(){
        this.setState({
            x: this.state.x++,
        })
        return(this.state.x+1);
    }

    handleDph(event){
        if(!this.state.platcasro){
            this.setState({
                price: 549,
                allprice: this.state.allprice - 239 + 549,
            });
        }
        else{
            this.setState({
                price: 239,
                allprice: this.state.allprice - 549 + 239,
            });
        }
        this.setState({
            platcasro: !this.state.platcasro
        })

    }
    handleBillingName(event){
        this.setState({
            billing_name: event.target.value,
        })
    }
    handleBillingLastname(event){
        this.setState({
            billing_lastname: event.target.value,
        })
    }
    handleBillingStreet(event){
        this.setState({
            billing_street: event.target.value,
        })
    }
    handleBillingStreetNumber(event){
        this.setState({
            billing_street_number: event.target.value,
        })
    }
    handleBillingCity(event){
        this.setState({
            billing_city: event.target.value,
        })
    }
    handleBillingPostcode(event){
        this.setState({
            billing_postcode: event.target.value,
        })
    }
    handleBillingCompanyName(event){
        this.setState({
            billing_company_name: event.target.value,
        })
    }
    handleBillingIco(event){
        this.setState({
            billing_ico: event.target.value,
        })
    }
    handleBillingDic(event){
        this.setState({
            billing_dic: event.target.value,
        })
    }
    handleBillingIcDph(event){
        this.setState({
            billing_icdph: event.target.value,
        })
    }





    componentDidMount(){
        window.scrollTo(0, 0);
        this.props.addlinka("four");

        if(Array.isArray(this.props.doplnkove)){
            this.setState({
                price: this.props.price,
                zivnostiCena: this.props.zivnostiCena,
                doplnkove: this.props.doplnkove,
            })
        }
        else{
            this.setState({
                price: this.props.price,
                zivnostiCena: this.props.zivnostiCena,
                doplnkove: new Array(),
            })

        }

        var allprice = 0;

        allprice = allprice + this.props.price;
        allprice = allprice + this.props.zivnostiCena;

        if(Array.isArray(this.props.doplnkove) && this.props.doplnkove != null){
            var dop = this.props.doplnkove;
            console.log(dop);
          //  dop = JSON.parse(dop);
            dop.forEach(function (e) {
                allprice = allprice + e.price;
            }.bind(this));
        }

        this.setState({
            allprice: allprice,
        })




        var json = this.state.json;

        var one = JSON.parse(localStorage.getItem("firstSubmit"));
        var two = JSON.parse(localStorage.getItem("secondSubmit"));
        var three = JSON.parse(localStorage.getItem("thirdSubmit"));

        json.push({firstSubmit: one, secondSubmit: two, thirdSubmit: three});

        if(localStorage.getItem("fourthSubmit") != null){

        }
        else{
            localStorage.setItem("fourthSubmit", "proccess");
        }

        this.setState({
            json: json,
        });

        console.log(json);
    }

    handlePlatba(event){
        this.setState({
            platba: event.target.value,
        })

    }
    handleGdpr(){
        this.setState({
            gdpr: !this.state.gdpr,
        })
    }
    handleVop(){
        this.setState({
            vop: !this.state.vop,
        })
    }
    handleAllOk(){
        this.setState({
            ok: !this.state.ok,
        })
    }

    handleSubmitForm(event){
        if(this.state.email == ""){
            this.setState({email2: "Toto pole je povinné"})

        }
        else{
            this.setState({email2: ""})
        }
        if(this.state.password == ""){
            this.setState({password2: "Toto pole je povinné"})


        }
        else{
            this.setState({password2: ""})
        }
        if(this.state.platba == ""){
            this.setState({platba2: "Toto pole je povinné"})
        }
        else{
            this.setState({platba2: ""})
        }
        if(this.state.passwordRepeat == ""){
            this.setState({passwordRepeat2: "Toto pole je povinné"})

        }
        else{
            this.setState({passwordRepeat2: ""})
        }
        if(this.state.phone == ""){
            this.setState({phone2: "Toto pole je povinné"});
        }
        else{
            this.setState({phone2: ""})
        }
        if(this.state.gdpr == false){
            this.setState({
                gdpr2: "Toto pole je povinné!"

            })


        }
        else{
            this.setState({
                gdpr2: ""
            })
        }
        if(this.state.vop == false){
            this.setState({
                vop2: "Toto pole je povinné",
            })


        }
        else{
            this.setState({
                vop2: "",
            })
        }
        if(this.state.ok == false){
            this.setState({
                ok2: "Toto pole je povinné",
            })


        }
        else{
            this.setState({
                ok2: "",
            })
        }

        if(this.state.billing_name == ""){
            this.setState({
                billing_name2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_name2: "",
            })
        }
        if(this.state.billing_lastname == ""){
            this.setState({
                billing_lastname2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_lastname2: "",
            })
        }
        if(this.state.phone == ""){
            this.setState({
                phone2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                phone2: "",
            })
        }
        if(this.state.billing_street == ""){
            this.setState({
                billing_street2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_street2: "",
            })
        }
        if(this.state.billing_street_number == ""){
            this.setState({
                billing_street_number2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_street_number2: "",
            })
        }

        if(this.state.billing_postcode == ""){
            this.setState({
                billing_postcode2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_postcode2: "",
            })
        }

        if(this.state.billing_city == ""){
            this.setState({
                billing_city2: "Toto pole je povinné",
            })
        }
        else{
            this.setState({
                billing_city2: "",
            })
        }


        if(this.state.vop != false && this.state.gdpr != false && this.state.ok != false && this.state.platba != "" && this.state.phone != "" && this.state.billing_name != ""
        && this.state.billing_lastname != "" && this.state.billing_city != "" && this.state.billing_postcode != "" && this.state.billing_street_number != "" && this.state.billing_street != ""){
           var datas = {
               email: this.state.email,
               password: this.state.password,
               phone: this.state.password,
               danovy: this.state.danovy,
               send: this.state.send,
               dozvedeli: this.state.odporucanie,
               zlava: this.state.zlava,
               poznamka: this.state.poznamka,
               platba: this.state.platba,
               billing_name: this.state.billing_name,
               billing_lastname: this.state.billing_lastname,
               billing_postcode: this.state.billing_postcode,
               billing_street: this.state.billing_street,
               billing_street_number: this.state.billing_street_number,
               billing_city: this.state.billing_city,
               billing_company_name: this.state.billing_company_name,
               billing_ico: this.state.billing_ico,
               billing_dic: this.state.billing_dic,
               billing_icdph: this.state.billing_icdph,
               phone: this.state.phone,
               dph: this.state.platcasro,
               papiere: this.state.papiere,

           }


            console.log(JSON.stringify(datas));
            event.preventDefault();
            this.props.fourthSubmit(datas);
            this.props.makeFifth();
            event.preventDefault();
            return;

        }
        else{
            event.preventDefault();
            return;
        }

    }
    handleDozvedeli(event){
        this.setState({odporucanie: event.target.value})
    }
    handleKupon(event){
        this.setState({zlava: event.target.value})
    }
    handlePoznamka(event){
        this.setState({poznamka: event.target.value})
    }
    phone(event){
        this.setState({phone: event.target.value})
    }
    handleEmail(event){
        this.setState({email: event.target.value})
    }
    handlePassword(event){
        this.setState({password: event.target.value})
    }
    handlePasswordRepeat(event){
        this.setState({passwordRepeat: event.target.value})
    }
    handlePhone(event){
        this.setState({
            phone: event.target.value,
        })
    }

    handlePosta(){

        this.setState({send: !this.state.send})
    }
    handleDanovy(event){
        this.setState({danovy: event.target.value});

    }

    render(){
        if(this.state.json.length > 0) {
            return (
                <div>
                    <NotificationContainer/>
                    <div className="container">
                        <div className="row ods">
                            <div className="col-md-12">
                                <h2 className="center">ZÁVEREČNÉ ÚDAJE PRED DOKONČENÍM</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid blue">
                        <div className="container">

                            <div className="row">
                                <div className="col-md-8">
                                    <div className="form-row firmazvol">
                                        <div className="col-12">
                                            <h2 className="nadpis">ŽIADAM O REGISTRÁCIU FIRMY NA DAŇOVOM ÚRADE (DIČ)</h2><br/>
                                            <input value={true} name="danovy" onChange={this.handleDanovy} type="radio"
                                                   className="form-control inline"/>&nbsp;Žiadam<br/>
                                            <input value={false} name="danovy" onChange={this.handleDanovy} type="radio"
                                                   className="form-control inline"/>&nbsp;Nežiadam, zaregistrujem sám /
                                            sama

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">

                            <div className="row ods">

                                <div className="col-md-8">
                                    <h2 className="nadpis">Iné (zľava)</h2>
                                    <div className="form-row firmazvol">
                                        <label>Tel. číslo*</label>
                                        <input value={this.state.phone} className="form-control" onChange={this.handlePhone}/>
                                        <span className="error">{this.state.phone2}</span>
                                    </div>
                                    <div className="form-row firmazvol">
                                        <div className="col-12">
                                            <label>Ako ste sa o nás dozvedeli</label>
                                            <input value={this.state.odporucanie} onChange={this.handleDozvedeli}
                                                   type="text" className="form-control"/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-row firmazvol">
                                        <div className="col-12">
                                            <label>Zľavový kupón</label>
                                            <input value={this.state.zlava} onChange={this.handleKupon} type="text"
                                                   className="form-control"/>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="form-row firmazvol">
                                        <div className="col-12">
                                            <label>Poznámka</label>
                                            <textarea value={this.state.poznamka} onChange={this.handlePoznamka}
                                                      type="text" className="form-control"></textarea>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-md-8">
                                    <br/>
                                    <h2>Súhrn objednávky</h2>

                                    <p><strong>Názov
                                        spoločnosti:</strong> {this.state.json[0].firstSubmit.nazov_spolocnosti} {this.state.json[0].firstSubmit.slug_spolocnosti}</p>
                                    <p>E-Mail: {this.state.json[0].firstSubmit.email}</p>
                                    {this.state.json[0].firstSubmit.sidlo == 'vlastne' ?
                                        <div>
                                            <p><strong>Sídlo
                                                spoločnosti:</strong> {this.state.json[0].firstSubmit.ulica} {this.state.json[0].firstSubmit.supisneCislo}/{this.state.json[0].firstSubmit.orientacneCislo}, {this.state.json[0].firstSubmit.obec} {this.state.json[0].firstSubmit.psc} {this.state.json[0].firstSubmit.stat}</p>
                                            <p><strong>Druh priestoru:</strong> {this.state.json[0].firstSubmit.druh} </p>
                                            <p><strong>Vlastník priestoru:</strong> {this.state.json[0].firstSubmit.vlastnik}</p>
                                        </div>
                                        : <p><strong>Sídlo spoločnosti:</strong> Werferova 6, 040 11 Košice</p>}
                                    <br/>
                                    <h5>Spoločníci</h5>
                                    <p><strong>Výška zakladného imania:</strong> {this.state.json[0].secondSubmit.imanie}€</p>
                                    <p><strong>Rozsah splatenia zakladného
                                        imania:</strong> {this.state.json[0].secondSubmit.splatene}€</p>

                                    {this.state.json[0].secondSubmit.spolocnici.map((data,index) => {
                                        return 1==1 ?
                                            <div>
                                                <hr/>
                                                {data.justkonatel == false || data.justkonatel == null ?
                                                <h5>Spoločník</h5>
                                                    : <h5>Konateľ</h5> }
                                                <hr/>
                                                {data.osoba == 'fyzicka' ?
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row">
                                                                <div className="col-md-6">

                                                                    <p><strong>Meno a
                                                                        priezvisko:</strong> {data.titul} {data.meno} {data.priezvisko} {data.titul2}
                                                                    </p>
                                                                    <p>
                                                                        <strong>Adresa:</strong> {data.ulica} {data.supCislo} {data.obec} {data.psc} {data.stat}
                                                                    </p>
                                                                    <p><strong>Dátum
                                                                        narodenia:</strong> {data.datumNarodenia}</p>
                                                                    <p><strong>Rodné číslo:</strong> {data.rodneCislo}
                                                                    </p>
                                                                    <p><strong>Typ dokladu
                                                                        totožnosti:</strong> {data.typDokladuTotoznosti}
                                                                    </p>
                                                                    <p><strong>Číslo dokladu
                                                                        totožnosti:</strong> {data.cisloDokladuTotoznosti}
                                                                    </p>
                                                                    {data.justkonatel == false || data.justkonatel == null ?
                                                                        <div>
                                                                            <p><strong>Výška
                                                                                vkladu:</strong> {data.vyskaVkladu}</p>
                                                                            <p><strong>Podieľ
                                                                                spoločnosti:</strong> {data.podielSpolocnost}
                                                                            </p>
                                                                            <p><strong>Rozsah
                                                                                splatenia:</strong> {data.rozsahSplatenia}
                                                                            </p>
                                                                        </div>
                                                                        : null}
                                                                    <p><strong>Konateľ:</strong> {data.konatel == true ?
                                                                        <span>Áno</span> :
                                                                        <span>Nie</span>}</p>
                                                                    {data.justkonatel == false || data.justkonatel == null ?
                                                                        <div>
                                                                            <p><strong>Správca
                                                                                vkladu:</strong> {data.spravcaVkladu == true ?
                                                                                <span>Áno</span> : <span>Nie</span>}</p>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                                {data.konatel == true ?
                                                                    <div className="col-md-6">
                                                                        <p><strong>Miesto
                                                                            narodenia:</strong> {data.registerTrestov.miestoNarodenia}
                                                                        </p>
                                                                        <p><strong>Štatná
                                                                            príslušnosť:</strong> {data.registerTrestov.statnaPrislusnost}
                                                                        </p>
                                                                        <p><strong>Rodné
                                                                            priezvisko:</strong> {data.registerTrestov.rodnePriezvisko}
                                                                        </p>
                                                                        <p><strong>Predchadzajúce
                                                                            priezvisko:</strong> {data.registerTrestov.predchadzajucePriezvisko}
                                                                        </p>
                                                                        <p><strong>Meno
                                                                            otca:</strong> {data.registerTrestov.menoOtca}
                                                                        </p>
                                                                        <p><strong>Priezvisko
                                                                            otca:</strong> {data.registerTrestov.priezviskoOtca}
                                                                        </p>
                                                                        <p><strong>Meno
                                                                            matky:</strong> {data.registerTrestov.menoMatky}
                                                                        </p>
                                                                        <p><strong>Priezvisko
                                                                            matky:</strong> {data.registerTrestov.priezviskoMatky}
                                                                        </p>
                                                                        <p><strong>Rodné priezvisko
                                                                            matky:</strong> {data.registerTrestov.rodnePriezviskoMatky}
                                                                        </p>
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                        </div>

                                                    </div>
                                                    : <div>

                                                        <p><strong>IČO:</strong> {data.ico}</p>
                                                        <p><strong>Obchodné meno:</strong> {data.obchodneMeno}</p>
                                                        <p>
                                                            <strong>Adresa:</strong> {data.ulica} {data.supisneCisloFirma}, {data.psc} {data.obec} {data.stat}
                                                        </p>

                                                        <p><strong>Výška vkladu:</strong> {data.vyskaVkladuFirma}€</p>
                                                        <p><strong>Podieľ v
                                                            spoločnosti:</strong> {data.podielSpolocnostFirma}</p>
                                                        <p><strong>Rozsah
                                                            splatenia:</strong> {data.rozsahSplateniaFirma}€</p>
                                                        <p><strong>Meno konateľa:</strong> {data.konatel.meno}</p>
                                                        <p><strong>Priezvisko
                                                            konateľa:</strong> {data.konatel.priezvisko}</p>
                                                        <p><strong>Dátum
                                                            narodenia:</strong> {data.konatel.datumNarodenia}</p>
                                                        <p><strong>Rodné číslo:</strong> {data.konatel.rodneCislo}</p>


                                                    </div>


                                                }
                                                <p><strong>Konečným úžívateľom výhod
                                                    sú:</strong> {this.state.json[0].secondSubmit.uzivatelVyhod == "zakladatelia" ?
                                                    <span>Spoločníci/zakladatelia</span> :
                                                    <span>Iné osoby - {this.state.json[0].secondSubmit.uzivatelVyhodText} </span>}
                                                </p>
                                                <p><strong>Spôsob konania
                                                    konateľov:</strong> {this.state.json[0].secondSubmit.konanieKonatelov == "samostatne" ?
                                                    <span>V mene spoločnosti koná a podpisuje každý konateľ samostatne</span> : null}
                                                    {this.state.json[0].secondSubmit.konanieKonatelov == "spolocne" ?
                                                        <span>V mene spoločnosti konajú všetci konatelia spoločne</span> : null}
                                                    {this.state.json[0].secondSubmit.konanieKonatelov == "ine" ?
                                                        <span>{this.state.json[0].secondSubmit.konanieKonatelovText}</span> : null}
                                                </p>

                                            </div>
                                            : null } )}

                                    <h5>Predmety podnikania</h5>
                                    {this.state.json[0].thirdSubmit.map((data, index) =>
                                        <div>
                                            <hr/>
                                        <h6>{index+1}. {data.name}</h6>
                                            <hr/>

                                            <div className="row">

                                        { (data.zastupca != null && data.zastupca != "") ?
                                            <div className="col-md-8">
                                                <h6>Zástupca:</h6>
                                                <p><strong>Meno a priezvisko:</strong> {data.zastupca.titul} {data.zastupca.meno} {data.zastupca.priezvisko} {data.zastupca.titul2}</p>
                                                <p><strong>Adresa:</strong> {data.zastupca.ulica} {data.zastupca.supCislo}, {data.zastupca.psc} {data.zastupca.obec}, {data.zastupca.stat}</p>
                                                <p><strong>Dátum narodenia:</strong> {data.zastupca.datumNarodenia}</p>
                                                <p><strong>Rodné číslo:</strong> {data.zastupca.rodneCislo}</p>
                                                <p><strong>Typ dokladu:</strong> {data.zastupca.totoznost}</p>
                                                <p><strong>Číslo dokladu:</strong> {data.zastupca.cisloTotoznosti}</p>
                                                {data.zastupca.konatel == true ?
                                                    <div>
                                                <p><strong>Miesto narodenia:</strong> {data.zastupca.registerTrestov.miestoNarodenia}</p>
                                                <p><strong>Štátna príslušnosť:</strong> {data.zastupca.registerTrestov.statnaPrislusnost}</p>
                                                <p><strong>Rodné priezvisko:</strong> {data.zastupca.registerTrestov.rodnePriezvisko}</p>
                                                <p><strong>Predchadzajúce priezvisko:</strong> {data.zastupca.registerTrestov.predchadzajucePriezvisko}</p>
                                                <p><strong>Meno otca:</strong> {data.zastupca.registerTrestov.menoOtca}</p>
                                                <p><strong>Priezvisko otca:</strong> {data.zastupca.registerTrestov.priezviskoOtca}</p>
                                                <p><strong>Meno matky:</strong> {data.zastupca.registerTrestov.menoMatky}</p>
                                                <p><strong>Priezvisko matky:</strong> {data.zastupca.registerTrestov.priezviskoMatky}</p>
                                                <p><strong>Rodné priezvisko matky:</strong> {data.zastupca.registerTrestov.rodnePriezviskoMatky}</p>
                                                    </div>:null}
                                            </div>

                                            :null
                                        }

                                            {(data.prevadzkaren != null && data.prevadzkaren != "") ?
                                                <div className="col-md-4">
                                                    <h6>Prevadzkáreň:</h6>
                                                    <p>Adresa: {data.prevadzkaren.ulica} {data.prevadzkaren.cislo}, {data.prevadzkaren.psc} {data.prevadzkaren.obec}</p>

                                                </div>
                                                : null}
                                            </div>

                                        </div>

                                    )}

                                    <h5>Cena:</h5>

                                    {this.state.papiere == false?
                                    <div>
                                        <p className="cenapric">Založenie: {this.state.price}€</p>

                                        {this.state.doplnkove.map(data=>
                                            <p className="cenapric">{data.name} - {Math.round(data.price*100)/100}€</p>
                                        )}
                                        {this.state.zivnostiCena > 0 ?
                                            <p className="cenapric">Živnosti - {this.state.zivnostiCena}€</p>
                                            : null}

                                        <p className="cenapric allpricone">SPOLU: {this.state.allprice}€</p>

                                    </div>



                                        :<div>  <p className="cenapric">Vypracovanie dokumentov: 59€</p>  <p className="cenapric allpricone">SPOLU: 59€</p></div>
                                    }



                                </div>
                                <div className="col-md-8">
                                    <div className="form-row firmazvol">
                                        <div className="col-12">
                                            <br/>

                                            {this.state.platcasro == false ?
                                                <div>

                                            <input className="noh" type="checkbox" onChange={this.handlePapiere} checked={this.state.papiere == true ? true : false} /> Mám záujem iba o vypracovanie dokumentov - cena 59€
                                                </div>
                                                : null }

                                                <br/>

                                            {this.state.papiere == false ?
                                                <div>

                                                    <input className="noh" type="checkbox" onChange={this.handleDph} checked={this.state.platcasro == true ? true : false} /> Mám záujem o založenie s.r.o. platca DPH</div>
                                                : null }

                                            <br/>

                                            <input className="noh" onChange={this.handleVop} type="checkbox"/> Súhlasím
                                            s obchodnými podmienkami (<a target="_blank"
                                            href="https://www.firmaprefirmy.sk/obchodne-podmienky">Viac informácií</a>)<br/>
                                            <span className="error"> {this.state.vop2}</span>
                                            <br/>
                                            <input className="noh" onChange={this.handleAllOk}
                                                   type="checkbox"/> Vyhlasujem, že všetky uvedené údaje sú správne<br/>
                                            <span className="error">{this.state.ok2}</span>
                                            <br/>
                                            <input className="noh" onChange={this.handleGdpr} type="checkbox"/> Súhlasím
                                            so spracovaním osobných údajov v súlade s nariadením GDPR o ochrane osobných
                                            údajov (<a target="_blank" href="https://www.firmaprefirmy.sk/ochrana-sukromia">Viac
                                            informácií.</a>)
                                            <br/>
                                            <span className="error">{this.state.gdpr2}</span>
                                            <br/><br/>
                                            <h2 className="nadpis">Fakturačné údaje</h2>

                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label name="billing_name">Meno*</label><br/>
                                                    <input onChange={this.handleBillingName} className="form-control" type="text" name="billing_name" value={this.state.billing_name}/>
                                                    <span className="error">{this.state.billing_name2}</span>
                                                </div>
                                                <div className="col-md-6">
                                                    <label name="billing_lastname">Priezvisko*</label><br/>
                                                    <input onChange={this.handleBillingLastname} className="form-control" type="text" name="billing_name" value={this.state.billing_lastname}/>
                                                    <span className="error">{this.state.billing_lastname2}</span>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="form-row">
                                                <div className="col-md-8">
                                                    <label name="billing_name">Ulica*</label><br/>
                                                    <input onChange={this.handleBillingStreet}  className="form-control" type="text" name="billing_name" value={this.state.billing_street}/>
                                                    <span className="error">{this.state.billing_street2}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label name="billing_lastname">Číslo ulice*</label><br/>
                                                    <input onChange={this.handleBillingStreetNumber} className="form-control" type="text" name="billing_name" value={this.state.billing_street_number}/>
                                                    <span className="error">{this.state.billing_street_number2}</span>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="form-row">
                                                <div className="col-md-8">
                                                    <label name="billing_name">Mesto*</label><br/>
                                                    <input onChange={this.handleBillingCity} className="form-control" type="text" name="billing_name" value={this.state.billing_city}/>
                                                    <span className="error">{this.state.billing_city2}</span>
                                                </div>
                                                <div className="col-md-4">
                                                    <label name="billing_lastname">PSČ*</label><br/>
                                                    <input onChange={this.handleBillingPostcode} className="form-control" type="text" name="billing_name" value={this.state.billing_street_postcode}/>
                                                    <span className="error">{this.state.billing_postcode2}</span>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label name="billing_name">Názov spoločnosti</label><br/>
                                                    <input onChange={this.handleBillingCompanyName} className="form-control" type="text" name="billing_name" value={this.state.billing_company_name}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label name="billing_lastname">IČO</label><br/>
                                                    <input onChange={this.handleBillingIco} className="form-control" type="text" name="billing_name" value={this.state.billing_street_ico}/>
                                                </div>
                                            </div>
                                            <br/>
                                            <div className="form-row">
                                                <div className="col-md-6">
                                                    <label name="billing_name">DIČ</label><br/>
                                                    <input onChange={this.handleBillingDic} className="form-control" type="text" name="billing_name" value={this.state.billing_dic}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label name="billing_lastname">IČ DPH</label><br/>
                                                    <input onChange={this.handleBillingIcDph} className="form-control" type="text" name="billing_name" value={this.state.billing_icdph}/>
                                                </div>
                                            </div>
                                            <br/>
                                            <input checked="true" className="noh" onChange={this.handlePlatba} type="radio"
                                                   name="platba" checked={this.state.platba == 'prevod' ? true : false} value="prevod"/> Budem platiť prevodom <br/>
                                            <input checked="true" className="noh" onChange={this.handlePlatba} type="radio"
                                                   name="platba" checked={this.state.platba == 'online' ? true : false} value="online"/> Budem platiť kartou online <br/><br/>



                                            {/*<input className="noh" onChange={this.handlePlatba} type="radio"*/}
                                                   {/*name="platba" value="online"/> Budem platiť kartou online <br/>*/}
                                            <span className="error"> {this.state.platba2} </span>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmitForm}>
                        <div className="container">
                            <div className="row ods odsbot">
                                <div className="col-md-12 centerize firmazvol">
                                    <input type="submit" className="btn btn-primary" value="Pokračovať"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }
        else{
            return(<div></div>);
        }

    }
}

export default CompanyReview;