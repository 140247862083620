import React, { Component } from 'react';
import logo from './logo.svg';
import axios from 'axios';
import './App.css';
import ReactTooltip from 'react-tooltip'
import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';


class CompanyFinish extends Component{
    constructor(props){
        super(props);
        this.state = {
            virtual: "",
            online: "",
            order_id: "",
            online_url: "",

        }
        this.payorder = this.payorder.bind(this);
    }

    payorder(){
        var id = this.state.order_id;
        var url = "https://posta.firmaprefirmy.sk/api/payordersro";

        var datas = {
            id: id,
        }

        axios.post(url, datas).then(res=>{
            window.open(res.data, "_blank");
        })
    }

    componentDidMount(){
        window.scrollTo(0, 0);

        var pole = new Array();

        var first = localStorage.getItem("firstSubmit");
        var second = localStorage.getItem("secondSubmit");
        var third = localStorage.getItem("thirdSubmit");
        var four = localStorage.getItem("fourthSubmit");



        if(first != null && first != 'proccess'){
            first=JSON.parse(first);
        }
        else{
            first = this.props.firstSubmit;
        }

        if(second != null && second != 'proccess'){
            second = JSON.parse(second)
        }
        else{
            second = this.props.secondSubmit;
        }

        if(third != null && third != 'proccess'){
            third = JSON.parse(third);
        }
        else{
            third = this.props.thirdSubmit;
        }
        if(four != null && four != 'proccess'){
            four = JSON.parse(four);
        }
        else{
            four = this.props.fourthSubmit;
        }





        pole.push({firstSubmit: first, secondSubmit: second, thirdSubmit: third, fourthSubmit: four});

        var datas = JSON.stringify(pole);
        axios.post("https://posta.firmaprefirmy.sk/public/api/storeall", datas).then(res=>{
            //console.log(res);

            localStorage.removeItem("firstSubmit")
            localStorage.removeItem("secondSubmit")
            localStorage.removeItem("thirdSubmit")
            localStorage.removeItem("fourthSubmit")

            if(this.props.firstSubmit.sidlo != 'vlastne') {
                var url = "https://posta.firmaprefirmy.sk/registracia?program=" + this.props.firstSubmit.program;
                this.setState({
                    virtual: url,
                })
            }

                this.setState({
                    order_id: res.data,

                })

                if(four.platba == "online"){
                    this.setState({
                        online: true,
                    })
                }

                //window.location.replace(url);



            // if(this.props.fourthSubmit.sidlo == 'online'){
            //     window.location.replace("https://posta.firmaprefirmy.sk/");
            // }
        });


        console.log(JSON.stringify(pole));


    }

    render(){
        if(this.state.order_id == "") {
            return(
                <div className="blue minh">
                    <h2 className="nadpis center">Objednávka sa spracováva. Počkajte prosím.</h2>
                </div>
            );
        }

        else {
            return (
                <div className="blue minh">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                    <h2 className="nadpis center">Ďakujeme za objednávku.</h2>
                    <p className="center">Dokumenty Vám budú na mail doručené po zaplatení cez platobnú bránu do zhruba 20 minút (v rámci pracovného času), po tom čo prejdu našou kontrolou.
                        V prípade, že platbu kartou chcete zrealizovať neskôr, prihláste sa do svojho účtu, alebo zaplaťte prevodom podľa údajov z predfaktúry. Ďakujeme za pochopenie.</p>
                    <p className="center">
                        {this.state.online == true ?
                            <a onClick={this.payorder} target="_blank" className="btn btn-primary white">Platba
                                online</a>
                            : null
                        }
                        &nbsp;
                        {this.state.virtual != "" ?
                            <a target="_blank" className="btn btn-primary" href={this.state.virtual}>Objednávka
                                virtuálneho sídla</a>
                            : null}
                    </p>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}

export default CompanyFinish;